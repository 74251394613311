<template>
    <div class="q-summit-step-resources-footer" :style="{ background, color: textColor }">
        <QSummitStepContainer>
            <v-row>
                <h3 class="q-summit-step-resources-footer__title" v-if="html || $slots.default">Resources</h3>    
            </v-row>
            <v-row>
                <div v-if="html" class="q-summit-step-resources-footer__content" v-html="html" />
                <div v-else class="q-summit-step-resources-footer__content">
                    <slot />
                </div>
            </v-row>
        </QSummitStepContainer>
    </div>
</template>

<script>
import QSummitStepContainer from "./QSummitStepContainer.vue"

export default {
    components: { QSummitStepContainer },

    props: {
        html: { type: String, required: false },
        color: { type: String, default: 'Step' }
    },

    computed: {
        background () {
            if (this.color === 'Step') {
                return '#005851'
            } else if (this.color === 'Checkpoint') {
                return '#18438A'
            } else if (this.color === 'Milestone') {
                return '#0c4463'
            } else if (this.color === 'Challenge') {
                return '#FFFFFF'
            }

            return this.color
        },
        textColor() {
            if (this.color === 'Challenge') {
                return '#000'
            }

            return '#fff'
        }
    }
}
</script>

<style lang="scss">
$background: #005851;
$title-color: inherit;
$text-color: #fff;


.q-summit-step-resources-footer {
    background: $background;
    color: $text-color;
    padding: 3rem 0 4.5rem 0;

    &__title {
        position: relative;
        color: $title-color;
        margin-bottom: 2rem;
        font-size: 2.5rem;

        &:after {
            content: '';
            width: 3rem;
            height: 0.5rem;
            position: absolute;
            left: 0;
            top: 100%;
            background: $title-color;
            opacity: 0.5;
            transform: translateY(0.25rem);
        }
    }

    &__content {
        width: 100%;

        // DEVST-1279: Fix opacity for some kind of cms blocks 
        .button-2, .button-1 {
            opacity: 1 !important;
        }

        .button-1:hover, .button-2:hover {
            color: white !important;
        }

        a:hover {
            color: inherit !important;
        }
    }
}
</style>