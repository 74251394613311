<template>
    <odd-bg class="q-summit-row__top-bg" v-if="index % 2 === 1" :index="index" />
    <even-bg class="q-summit-row__top-bg" v-else-if="index % 2 === 0" :index="index" />
</template>

<script>
import OddBg from './images/OddBg.vue'
import EvenBg from './images/EvenBg.vue'

export default {
    components: { EvenBg, OddBg },

    props: {
        index: { type: Number, required: true },
    }
}
</script>