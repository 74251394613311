<template>
    <QSummitStepBackgroundBottom>
        <!-- <QSummitStepBackgroundBottomRight class="q-summit-step-background-bottom__right" />             
        <QSummitStepBackgroundBottomLeft class="q-summit-step-background-bottom__left" />     -->
        <QSummitStepBackgroundBottomCenter class="q-summit-step-background-bottom__center" />        
    </QSummitStepBackgroundBottom>
</template>

<script>
import QSummitStepBackgroundBottomRight from "./bottom/QSummitStepBackgroundBottomRight.vue";
import QSummitStepBackgroundBottomLeft from "./bottom/QSummitStepBackgroundBottomLeft.vue";
import QSummitStepBackgroundBottomCenter from "./bottom/QSummitStepBackgroundBottomCenter.vue";
import QSummitStepBackgroundBottom from '../_base/QSummitStepBackgroundBottom.vue'

export default {
    components: { QSummitStepBackgroundBottom, QSummitStepBackgroundBottomRight, QSummitStepBackgroundBottomLeft, QSummitStepBackgroundBottomCenter }
}
</script>

<style lang="scss" scoped>
.q-summit-step-background-bottom {
    &__center {
        transform: translateX(-50%) translateY(10px);
    }
}
</style>