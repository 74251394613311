Thank - <template>
    <v-container fluid class="cms cms-editor my-0 px-0  py-0 cms site-width">
        <div class="lol-key">
            <v-card class="pa-3 d-none d-sm-flex">
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Key</v-list-item-title>
                        <v-list-item-subtitle><span class="q_blue_2--text">AB</span> = Advisory Board</v-list-item-subtitle>
                        <v-divider class=""></v-divider>
                        <v-list-item-subtitle><span class="q_blue_2--text">EP</span> = Equity Partner</v-list-item-subtitle>
                        <v-divider class=""></v-divider>
                        <v-list-item-subtitle><span class="q_blue_2--text">TP</span> = Top Producer</v-list-item-subtitle>
                        <v-divider class=""></v-divider>
                        <v-list-item-subtitle><span class="q_blue_2--text">ELP</span> = Elite Producer</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-card>
        </div>
        <page slug="levels-of-leadership-top-of-page"></page>
        <div class="row pa-5 d-none d-sm-flex">
            <v-col cols="12">
                <v-row>
                    <v-col cols="12" class="agent_profiles">
                        <h2><span class="first-with-short-rule">Levels of Leadership</span></h2>
                        <div v-if="hasPermission(['manage:Content'])">
                            <v-btn fab absolute right center small :to="{name:'EditLevelsOfLeadership'}">
                                <v-icon small>fas fa-pencil-alt</v-icon>
                            </v-btn>
                        </div>
                        <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
                        <div v-for="(section, key) in sections" :key="key">
                            <v-divider class="mt-4 mb-4"></v-divider>
                            <h4><span class="with-short-rule">{{section}}</span></h4>
                            <v-row wrap class="justify-left">
                                <v-col cols="6" md="2" sm="4" v-for="(agent, ix) in section_profiles(section)" :key="ix" class="px-5">
                                    <div class="agent_profile_photo" style="width:150px; margin:0 auto;">
                                        <img v-if="agent.ProfilePhoto" :src="agent.ProfilePhoto"></img><br>
                                    </div>
                                    <h5 class="agent-titles">
                                        {{agent.DisplayName}}
                                    </h5>
                                    <div class="text-center">
                                        <div class="body-2">
                                            {{agent.Titles.join(', ')}}
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </div>
        <!-- expansion pannels visible only on small -->
        <div class="d-sm-flex d-md-none">
            <v-col cols="12">
                <h2><span class="first-with-short-rule">Levels of Leadership</span></h2>
                <v-row>
                    <v-expansion-panels v-for="(section, key) in sections" :key="key">
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <h4><span class="with-short-rule">{{section}}</span></h4>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row wrap class="justify-left">
                                    <v-col cols="6" md="2" sm="4" v-for="(agent, ix) in section_profiles(section)" :key="ix" class="px-5">
                                        <div class="agent_profile_photo" style="width:150px; margin:0 auto;">
                                            <img v-if="agent.ProfilePhoto" :src="agent.ProfilePhoto"></img><br>
                                        </div>
                                        <h5 class="agent-titles">
                                            {{agent.DisplayName}}
                                        </h5>
                                        <div class="text-center">
                                            <div class="body-2">
                                                {{agent.Titles.join(', ')}}
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-row>
            </v-col>
        </div>
        <!-- /expansion pannels -->
    </v-container>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI'
import AgentProfile from '@/store/Models/AgentProfile'
import Page from '@/CMS/views/index.vue'
export default {
    data() {
        return {
            loading: false,
            sections: [],
            Division: "Leadership"
        }
    },
    mounted: function() {
        this.loading = true;
        var g = this;
        QuilityAPI.getAgentProfiles(this.Division).then(function(resp) {
            g.sections = resp.meta.Sections
            g.loading = false;
        }).catch(function(err) {
            g.showError(err)
            g.loading = false;
        })
    },
    computed: {

    },
    methods: {
        'section_profiles': function(section) {
            return AgentProfile.query().where('Division', this.Division).where('Section', section).orderBy('DisplayName', 'asc').get();
        },
    },
    components: {
        Page
    }
}

</script>
