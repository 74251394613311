<template>
    <v-container fluid class="grey lighten-5 pa-5">
        <q-lead-lookup></q-lead-lookup>
        <v-row class="ma-5">
            <v-col cols="12">
                <fml-question-set slug="the-titlevcxvxzcvxczv" question-set-index="0" v-model="response" v-on:input="logEvent" v-on:submitted="logEvent" v-on:saved="logEvent"></fml-question-set>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import FmlQuestionSet from '@/FML/views/FmlQuestionSet.vue'
import QLeadLookup from '@/components/utils/QLeadLookup.vue'

export default {
    data() {
        return {
            response: null,
            events: []
        }
    },
    methods: {
        logEvent: function(e) {
            this.events.push(e);
        }
    },
    components: {
        FmlQuestionSet,
        QLeadLookup
    }
}
</script>