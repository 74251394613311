
import QSummitVideoPlayerWrapper from './QSummitVideoPlayerWrapper.vue';
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { defineComponent, PropType } from 'vue'
import { WistiaVideo } from '@/CMS/types/blocks';

declare global {
  interface Window {
    /* Some Wistia global for tracking */
    _wq: {
      id: any,
      onReady: (...args: any[]) => void
    }[];
  }
}

/* WistiaPlayer with built-in Summit tracking and without title and text */
export default defineComponent({
    name: "QWistiaPlayer",

    components: { 
        QSummitVideoPlayerWrapper
    },

    props: {
        video: {
            type: Object as PropType<WistiaVideo>,
            required: true
        }
    },

    data: () => ({
        percentage_watched: 0
    }),

    mounted() {
        setTimeout(this.startTrackingVideo, 2000);
    },

    computed: {
        video_id() {
            return this.video.video_id;
        },
        defaultWistiaVideo() {
            return "https://fast.wistia.net/embed/iframe/" + this.video_id + "?videoFoam=true";
        },
        title() {
            return this.video.title;
        },
        getAlignment: function() {
            return "position:absolute; left:0; bottom:0; padding:20px; text-align:right; width:100%"
        }
    },
    methods: {
        //adds event watchers
        startTrackingVideo() {
            window._wq.push({
                id: this.video_id,
                onReady: (video: any) => {
                    video.bind('play', () => {
                        this.logPlayingStarted()
                    });
                    video.bind('end', () => {
                        this.logCompletedVideo()
                    });

                    video.bind('percentwatchedchanged', (p: number) => {
                        this.logPercentWatched(p)
                    });
                }
            });
        },
        logPlayingStarted() {
            QuilityAPI.logWistiaEvent("started", this.video_id)
        },
        logPercentWatched(percent: number) {
            var p = Math.round(percent * 10)
            if (p > this.percentage_watched) {
                console.log('watched' + p + "0%")
                this.percentage_watched = p;
                QuilityAPI.logWistiaEvent("watched " + Math.round(percent * 100) + "%", this.video_id)
            }
        },
        logCompletedVideo() {
            console.log('Video finished!')
            QuilityAPI.logWistiaEvent("finished", this.video_id)
        },
    }
})
