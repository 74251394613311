<template>
    <v-container fluid class="mb-10 pb-10 px-0  py-0">
        <div class="">
            <v-container fluid class="px-5">
                <v-row cols="12" width="100%" wrap class="d-flex" align="center" justify="center">
                    <v-col cols="12" sm="8">
                        <strong>SUMMIT</strong> {{step.StepCategory}}
                    </v-col>
                    <v-col cols="12" sm="8">
                        <v-row>
                            <v-col cols="10">
                                Step {{step.StepOrder + 1}}
                                <h1>{{step.StepTitle}}</h1>
                            </v-col>
                            <v-col cols="2">
                                <q-compass-progress style="height:125px; width:125px;" :total-steps="step.StepsInCategory" :current-step="step.StepCategoryOrder"></q-compass-progress>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="8">
                        <slot></slot>
                    </v-col>
                    <v-col cols="12" sm="8">
                        <v-btn v-if="!step.Complete" color="primary" @click="$emit('checkrequirements')" block large>Complete Step</v-btn>
                        <v-btn v-if="step.PreviousStepUrl" :to="step.PreviousStepUrl">Previous Step</v-btn>
                        <v-btn v-if="step.OptionalInd" @click="$emit('skipstep')">Skip</v-btn>
                        <v-btn v-else-if="step.NextStepUrl && !step.CurrentStepInd && step.Complete" :to="step.NextStepUrl">Next Step</v-btn>
                    </v-col>
                    <v-col cols="12" sm="8">
                        <h3>Resources</h3>
                        <div v-html="step.StepConfig.content.block.Resources"></div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </v-container>
</template>
<script>
import QCompassProgress from "@/components/summit/QCompassProgress.vue";

export default {
    props: ['step'],
    data: () => ({}),
    components: {
        QCompassProgress
    },
}

</script>
