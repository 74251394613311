<template>
    <div class="q-fif-survey" v-if="currentQuestion">
        <p class="body-1">{{ currentQuestion.title }}</p>

        <div class="q-fif-survey__question">
            <template v-if="isRadioQuestion(currentQuestion)">
                <v-radio-group v-model="radioAnswer" row>
                    <v-radio v-for="(answer, index) in currentQuestion.answers" :key="index" :label="answer.title" />
                </v-radio-group>
            </template>

            <template v-else>
                <template v-for="(answer, index) in currentQuestion.answers">
                    <v-text-field v-if="answer.type === 'input'" :key="index + answer.type" :label="answer.title"
                        v-model="inputAnswer" @click="currentAnswer = answer" />
                    <v-btn v-if="answer.type === 'button'" :key="index + answer.type" @click="onBtnClick(answer)" text>
                        {{ answer.title }}
                    </v-btn>
                </template>
            </template>
        </div>
        <div class="d-flex flex-row">
            <slot v-if="currentQuestionIndex" name="try-again" v-bind="{ resetAnswers }"></slot>
            <v-spacer></v-spacer>
            <v-btn color="q_blue_1" depressed @click="nextQuestion" :disabled="currentAnswer === null || currentAnswer === undefined">Continue</v-btn>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        questions: { type: Array, require: true },
    },

    data() {
        return {
            currentQuestionIndex: 0,
            answers: [],
            radioAnswer: -1,
            inputAnswer: "",
            currentAnswer: null,
        };
    },

    computed: {
        currentQuestion() {
            if (!this.questions) {
                return;
            }

            return this.questions[this.currentQuestionIndex];
        },
    },

    methods: {
        nextQuestion() {
            const answer =
                typeof this.currentAnswer.answer === "function"
                    ? this.currentAnswer.answer.call(this)
                    : this.currentAnswer.answer;

            this.answers.push({
                questionIndex: this.currentQuestionIndex,
                answer,
            });

            this.currentQuestionIndex = this.currentAnswer.to;

            if (this.currentQuestionIndex === -1) {
                this.$emit("finish", this.answers);
                this.answers = [];
            }
            if (this.currentQuestionIndex >= this.questions.length) {
                this.$emit("finish", this.answers);
                this.answers = [];
            }

            this.radioAnswer = 0;
            this.inputAnswer = "";

            this.$nextTick(() => {
                if (!this.currentQuestion) {
                    return;
                }

                if (this.isRadioQuestion(this.currentQuestion)) {
                    this.currentAnswer = this.currentQuestion.answers[this.radioAnswer];
                } else {
                    this.currentAnswer = null;
                }
            });
        },

        onBtnClick(answer) {
            this.currentAnswer = answer;
            this.nextQuestion();
        },

        isRadioQuestion(question) {
            return question.answers.every((answer) => answer.type === "radio");
        },
        isTextQuestion(question) {
            return question.answers.some((answer) => answer.type === "input");
        },
		resetAnswers () {
			this.currentQuestionIndex = 0;
			this.answers = [];
			this.radioAnswer = -1;
			this.inputAnswer = "";
			this.currentAnswer = null;
		},
    },

    watch: {
        radioAnswer: {
            handler(newValue) {
                if (this.currentQuestion) {
                    this.currentAnswer = this.currentQuestion.answers[newValue];
                }
            },
            immediate: true,
        },

        questions: {
            handler: 'resetAnswers',
            immediate: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.q-fif-survey {
    &__question {
        display: flex;
        align-items: center;

        & > * {
            margin-right: 2rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
</style>
