<template>
    <QSummitStepLayout>
        <template #header>
            <QSummitStepHeader :title="step.StepCategory">
                <template #right>
                    <slot name="header-right" />
                </template>
            </QSummitStepHeader>
        </template>
        <template #footer>
            <QSummitStepResourcesFooter :html="step.StepConfig.content.block.Resources" :color="step.StepType" />
        </template>

        <QSummitStep :step="step" v-on="$listeners" />
    </QSummitStepLayout>
</template>
<script>
import QSummitStepLayout from "../../components/summit/step-template/QSummitStepLayout.vue";
import QSummitStepResourcesFooter from "../../components/summit/step-template/QSummitStepResourcesFooter.vue";
import QSummitStepHeader from "../../components/summit/step-template/QSummitStepHeader.vue";
import QSummitStep from "../../components/summit/step-template/QSummitStep.vue";

export default {
    props: {
        step: { type: Object, required: true },
    },

    computed: {
        videos() { return this.step.StepConfig.content.block.Videos },
        fullWidthText() { return this.step.StepConfig.content.block },

        content() {
            if (this.step.StepConfig.content.block.Videos) {
                return this.step.StepConfig.content.block.Videos
            }

            if (this.step.StepConfig.content.block.Title) {
                return [this.step.StepConfig.content.block]
            }
        }
    },


    components: {
        QSummitStep,
        QSummitStepLayout,
        QSummitStepResourcesFooter,
        QSummitStepHeader,
    },
}
</script>