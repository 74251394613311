
import QSummitVideoPlayerWrapper from './QSummitVideoPlayerWrapper.vue';
import { defineComponent, PropType } from 'vue'
import { LightSpeedVideo } from '@/CMS/types/blocks';

export default defineComponent({
    name: "QSummitLightSpeedPlayer",

    components: { QSummitVideoPlayerWrapper },

    props: {
        video: {
            type: Object as PropType<LightSpeedVideo>,
            required: true
        }
    },

    computed: {
        agentCode() { return (this.user as any).AgentCode; },
    }
})
