
import { defineComponent, PropType } from "vue";
import type {
	FullWidthTextBlock,
	SummitLightSpeedVideoBlock,
	SummitWistiaVideoBlock,
	SummitQuizBlock,
} from "@/CMS/types/blocks";

import QSummitStepBackground from "../QSummitStepBackground.vue";
import QSummitStepTitle from "../QSummitStepTitle.vue";
import QSummitStepContainer from "../QSummitStepContainer.vue";
import QSummitStepContent from "../content-templates/QSummitStepContent.vue";
import QSummitStepControls from "../QSummitStepControls.vue";

export default defineComponent({
	components: {
		QSummitStepBackground,
		QSummitStepTitle,
		QSummitStepContainer,
		QSummitStepContent,
		QSummitStepControls,
	},

	props: {
		step: {
			type: Object as PropType<{
                StepType: string,
				StepConfig: {
					content:
						| SummitLightSpeedVideoBlock
						| SummitWistiaVideoBlock
						| FullWidthTextBlock
						| SummitQuizBlock;
				};
			}>,
			required: true,
		},
	},

	computed: {
		content() {
			if ("Videos" in this.step.StepConfig.content.block) {
				return this.step.StepConfig.content.block.Videos;
			}

			if ("Title" in this.step.StepConfig.content.block) {
				return [this.step.StepConfig.content.block];
			}

			if ("Quiz" in this.step.StepConfig.content.block) {
				return [this.step.StepConfig.content.block.Quiz];
			}

			return [];
		},
	},
});
