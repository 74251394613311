<template>
    <StepBackground v-if="color === 'Step'"><slot /></StepBackground>
    <CheckpointBackground v-else-if="color === 'Checkpoint'"><slot /></CheckpointBackground>
    <MilestoneBackground v-else-if="color === 'Milestone'"><slot /></MilestoneBackground>
    <ChallengeBackground v-else-if="color === 'Challenge'"><slot /></ChallengeBackground>
</template>

<script>
import CheckpointBackground from './step-background/checkpoint/QSummitStepCheckpointBackground.vue'
import StepBackground from './step-background/step/QSummitStepStepBackground.vue'
import MilestoneBackground from './step-background/milestone/QSummitStepMilestoneBackground.vue'
import ChallengeBackground from './step-background/challenge/QSummitStepChallengeBackground'

export default {
    components: { StepBackground, CheckpointBackground, MilestoneBackground, ChallengeBackground },

    props: {
        color: { type: String, default: 'Step', validator: (v) => ['Step', 'Checkpoint', 'Milestone', 'Challenge'].includes(v) }
    },
}
</script>
