import { computed } from "vue"

export const useSyncProp = <K extends string, Props extends Record<K, any>>(key: K, props: Props, emit: (event: any, ...args: any[]) => void) => {
  return computed<Props[K]>({
    get() { return props[key] },
    set(value: Props[K]) { emit(`update:${key}`, value) },
  })
}

export const useModelValue = <V>(props: { value?: V }, emit: (event: 'input', ...args: any[]) => void) => {
  return computed({
    get() { return props.value as V },
    set(value: V) { emit('input', value) },
  })
}