
import { defineComponent, PropType } from 'vue'
import type { FullWidthTextBlock, SummitLightSpeedVideoBlock, SummitWistiaVideoBlock, SummitQuizBlock } from '@/CMS/types/blocks'

import QSummitStepTitle from '../QSummitStepTitle.vue'
import QSummitStepControls from '../QSummitStepControls.vue'
import QSummitStepContent from '../content-templates/QSummitStepContent.vue'
import QSummitStepContainer from '../QSummitStepContainer.vue'
import TopBg from './striped/TopBg.vue'
import QSummitWistiaPlayer from '@/CMS/views/ContentBlocks/Elements/QSummitWistiaPlayer.vue'
import QSummitLightSpeed from '@/CMS/views/ContentBlocks/Elements/QSummitLightSpeedPlayer.vue'

export default defineComponent({
    components: {
        QSummitStepTitle,
        QSummitStepControls,
        QSummitStepContent,
        QSummitStepContainer,
        TopBg,
        QSummitWistiaPlayer,
        QSummitLightSpeed,
    },

    props: {
        step: { type: Object as PropType<{
            StepConfig: {
                content: SummitLightSpeedVideoBlock | SummitWistiaVideoBlock | FullWidthTextBlock | SummitQuizBlock
            }
        }>, required: true },
    },

    computed: {
        content() {
            if ('Videos' in this.step.StepConfig.content.block) {
                return this.step.StepConfig.content.block.Videos
            }
            
            if ('Quiz' in this.step.StepConfig.content.block) {
                return [this.step.StepConfig.content.block.Quiz]
            }

            if ('Title' in this.step.StepConfig.content.block) {
                return [this.step.StepConfig.content.block]
            }

            return []
        }
    },
})
