<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
      <div class="container header">
    <IconPage />
  </div>
  </v-container>
</template>

<script>
import IconPage from "./IconPage.vue";

export default {
  components: {
    IconPage
  }
};
</script>