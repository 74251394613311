<template>
    <v-dialog content-class="q-complete-step-dialog" v-model="valueSync" :width="$vuetify.breakpoint.smAndDown ? '80%' : '50%'" :persistent="persistent">
        <v-card class="q-complete-step-dialog__card" color="#0C4463">
            <template v-if="$props.loading">
                <img class="loading-animation mb-2" :src="require('@/assets/images/Sun-and-Clouds-Loading-Animation-css.svg')" />
                <h3 class="white--text">
                    {{ loadingText }}
                    <v-progress-circular indeterminate size="20"></v-progress-circular>
                </h3>
            </template>
            <template v-else>
                <v-card-text class="text-center white--text pb-0">
                    <h3 v-if="title" class="white--text"></h3>

                    <slot v-bind="{
                        vBtnProps: { color: 'white', style: { color: '#0C4463' }, class: 'q-complete-step-dialog__btn font-weight-bold', depressed: true }
                    }"/>
                </v-card-text>
            </template>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
        loadingText: { type: String, default: "Loading..." },
        title: { type: String, default: undefined },
        persistent: { type: Boolean, default: false }
    },

    computed: {
        valueSync: { get() { return this.value }, set(value) { this.$emit('input', value) } }
    }
}
</script>

<style lang="scss">
.q-complete-step-dialog {
    border-radius: 24px !important;
    overflow: hidden;

    &__card {
        padding: 32px;
        display: flex;
        align-items: center;
        flex-direction: column;

        .loading-animation {
            max-width: 125px;
        }
    }

    &__btn {
        border-radius: 6px;
    }
}
</style>
