<template>
    <v-row>
        <v-col cols="12" sm="6" md="8">
            <slot v-if="left" name="left" />
            <slot v-else name="right" />
        </v-col>

        <v-col cols="12" sm="6" md="4" class="d-flex flex-column">
            <slot v-if="left" name="right" />
            <v-spacer v-else />

            <slot v-if="$slots.controls" name="controls" />
        </v-col>
    </v-row>
</template>

<script>
export default {
    computed: {
        left() {
            return this.$slots.left && this.$slots.left.some((item) => item.tag !== undefined)
        },
        right() {
            return this.$slots.right && this.$slots.right.some((item) => item.tag !== undefined)
        },
    }
}
</script>
