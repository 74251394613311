
import { defineComponent, PropType } from 'vue'
import { SummitVideo } from '@/CMS/types/blocks/';

export default defineComponent({
    name: "QSummitVideoPlayerWrapper",

    props: {
        video: { type: Object as PropType<SummitVideo>, required: true },
    },

    methods: {
        noText(text: string | null | undefined) {
            return  text == null || text == "" || text == "<p></p>";
        }
    }
})
