import html2canvas from 'html2canvas'
import jsPdf from 'jspdf'

export default {
    methods: {
		async HTML2PDF (el, title) {
			const domElement = typeof el === 'string'
				? document.getElementById(el)
				: el
			
			if (!domElement) {
				this.showError('Could not generate PDF.<br>', 'Could not find DOM element.')
				return new Promise((resolve) => { resolve(false) })
			}

			const { width: widthStr, height: heightStr } = getComputedStyle(domElement)
			const width = parseInt(widthStr, 10)
			const height = parseInt(heightStr, 10)
			return html2canvas(domElement, { windowWidth: width, windowHeight: height })
				.then((canvas) => {
					const pdf = new jsPdf({ unit: 'px', format: [width, height], orientation: 'p' })
					pdf.addImage(canvas, 'JPEG', 0, 0, width, height)
					pdf.save(`${title}.pdf`)
					return true
				})
				.catch((err) => {
					this.showError('Could not generate PDF.<br>', err.message)
					return false
				})
        },
    },
}
