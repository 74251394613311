
import { defineComponent, PropType } from 'vue'
import type { FullWidthTextBlock, SummitLightSpeedVideoBlock, SummitWistiaVideoBlock, SummitQuizBlock, SummitVideo, SummitQuiz, FullWidthText } from '@/CMS/types/blocks'
import Gallery from './cinema/VerticalGallery.vue'
import BgImage from './cinema/BgImage.vue'

import QSummitStepTitle from '../QSummitStepTitle.vue'
import QSummitStepControls from '../QSummitStepControls.vue'
import QSummitStepContent from '../content-templates/QSummitStepContent.vue'
import QSummitStepContainer from '../QSummitStepContainer.vue'
import TopBg from './striped/TopBg.vue'
import QSummitWistiaPlayer from '@/CMS/views/ContentBlocks/Elements/QSummitWistiaPlayer.vue'
import QSummitLightSpeed from '@/CMS/views/ContentBlocks/Elements/QSummitLightSpeedPlayer.vue'

export default defineComponent({
    components: {
        QSummitStepTitle,
        QSummitStepControls,
        QSummitStepContent,
        QSummitStepContainer,
        TopBg,
        QSummitWistiaPlayer,
        QSummitLightSpeed,
        Gallery,
        BgImage
    },

    props: {
        step: { type: Object as PropType<{
            StepConfig: {
                content: SummitLightSpeedVideoBlock | SummitWistiaVideoBlock | FullWidthTextBlock | SummitQuizBlock
            }
        }>, required: true },
    },

    data() {
        return {
            selectedContent: null as SummitVideo | SummitQuiz | FullWidthText | null,
        }
    },

    mounted() {
        this.selectedContent = this.content[0]
    },

    computed: {
        content() {
            if ('Videos' in this.step.StepConfig.content.block) {
                return this.step.StepConfig.content.block.Videos
            }
            
            if ('Quiz' in this.step.StepConfig.content.block) {
                return [this.step.StepConfig.content.block.Quiz]
            }

            if ('Title' in this.step.StepConfig.content.block) {
                return [this.step.StepConfig.content.block]
            }

            return []
        },
        selectedContentKey() {
            if (!this.selectedContent) {
                return ''
            }

            if ('video_id' in this.selectedContent) {
                return this.selectedContent.video_id + this.selectedContent.title
            }

            // Just in case if text or quiz is used in Cinema layout
            if ('quiz_slug' in this.selectedContent) {
                return this.selectedContent.quiz_slug
            }

            return this.selectedContent.text
        }
    },

    watch: {
        content: {
            handler() {
                this.selectedContent = this.content[0]
            },
            deep: true,
            immediate: true
        }
    }
})
