<template>
  <div>
      <div class="d-flex justify-space-between">
          <span>
                <v-btn text color="q_green_1" v-if="step.PreviousStepUrl" :to="step.PreviousStepUrl" class="mb-2 font-weight-black">
                    <v-icon class="mr-2">fas fa-chevron-left</v-icon> Back
                </v-btn>
          </span>
          <span>
                <v-btn text color="q_green_1" @click="$emit('skipstep')" v-if="step.OptionalInd && !step.Complete" class="mb-2 font-weight-black">
                     Skip <v-icon class="ml-2">fas fa-chevron-right</v-icon>
                </v-btn>
                <v-btn
                    v-else-if="step.NextStepUrl && !step.CurrentStepInd && step.Complete" 
                    :to="step.NextStepUrl"
                    text color="q_green_1" 
                    class="mb-2 font-weight-black"
                >
                    Next Step
                    <v-icon class="ml-2">fas fa-chevron-right</v-icon>
                </v-btn>
          </span>
      </div>

      <v-btn large color="#FF5851" width="100%" dark depressed v-if="!step.Complete" class="font-weight-black" @click="$emit('checkrequirements')">
          Complete this step
      </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    step: { type: Object, required: true },
  }
}
</script>