<template>
    <v-container fluid class="grey lighten-5 my-0 px-0 py-0">
        <QMyClientsSubmenu />
		<FIFForm />
    </v-container>
</template>

<script>
import { defineComponent } from 'vue'

import QMyClientsSubmenu from '@/components/navigation/Submenus/QMyClientsSubmenu.vue'
import FIFForm from './FIFForm.vue'

export default defineComponent({
	components: {
		QMyClientsSubmenu,
		FIFForm,
	},
})
</script>
