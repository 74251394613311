<template>
    <div class="q-summit-step-header" :class="{ 'q-summit-step-header--mobile': $vuetify.breakpoint.smAndDown }">
        <QSummitStepContainer>
            <div class="q-summit-step-header__content">
                <slot>
                    <div class="q-summit-step-header__side q-summit-step-header__left">
                        <div class="q-summit-step-header__summit-logo">
                            SUMMIT
                        </div>
                        <div v-if="title" class="q-summit-step-header__title ml-2">
                            {{ title }}
                        </div>
                    </div>
                    <div class="q-summit-step-header__side q-summit-step-header__right">
                        <slot name="right" />
                        <v-btn icon to="/summit">
                            <v-icon class="ml-2" color="white">
                                fas fa-home
                            </v-icon>
                        </v-btn>
                    </div>
                </slot>
            </div>
        </QSummitStepContainer>
    </div>
</template>
<script>
import QSummitStepContainer from "./QSummitStepContainer.vue"

export default {
    components: { QSummitStepContainer },

    props: {
        title: { type: String }
    }
}
</script>
<style lang="scss">
$background: #0C4463;

.q-summit-step-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $background;
    padding: 1rem 0;

    &__side {
        display: flex;
    }

    &__summit-logo {
        text-align: left;
        font: normal normal 800 32px/50px Montserrat;
        letter-spacing: 0px;
        color: #46C3B2;
    }

    &__title {
        text-align: left;
        font: normal normal normal 32px/50px Montserrat;
        letter-spacing: 0px;
        color: #FFFFFF;
    }

    &__content {
        display: flex;
        justify-content: space-between;
    }

    &--mobile {
        .q-summit-step-header {
            padding: 0.5rem 0;

            &__summit-logo {
                font: normal normal normal 20px/24px Montserrat;
            }

            &__title {
                font: normal normal normal 20px/24px Montserrat;
            }
        }
    }
}
</style>