<template>
	<div v-if="error" class="text-danger">
		{{ error }}
	</div>
	<v-progress-circular left indeterminate size="25" v-else-if="isLoading" />
	<div style="width: 100%" v-else>
		<h5>Choose an agent and set an appointment</h5>
		<p v-if="calendarsListDescription" class="mb-2">{{ calendarsListDescription }}</p>
		<div class="agent-calendars">
			<v-btn
				v-for="(link, idx) in links"
				:key="idx + link.title + link.url"
				:class="isMobile && 'my-1 px-1'"
				color="q_blue_1"
				target="_blank"
				:href="link.url"
				:loading="isLoading"
				:disabled="!link.url"
				text
				:small="isMobile"
				@click="updateSME(link)"
			>
				<v-icon lef>icon-events</v-icon>
				<v-divider class="mx-1" vertical></v-divider>
				{{ isMobile ? link.title : `Open ${link.title}'s Calendar`}}
			</v-btn>
		</div>
		<div v-if="selectedSME" class="mt-8">
			Confirm that you have an appointment with <b>{{ selectedAgent }}</b>
		</div>
		<div class="d-flex flex-wrap justify-end mt-6" style="width: 100%">
            <slot name="try-again" />
            <v-spacer />
			<v-btn
				color="q_blue_1"
				depressed
				:disabled="!selectedSME"
				@click="confirmSME"
			>
				Confirm
			</v-btn>
		</div>
		<div class="d-flex flex-column align-end mt-4">
			<v-btn color="q_blue_1" outlined v-if="selectedSME" @click="selectedSME = null">
				{{ isMobile ? 'Reset appointment' : 'Set an appointment with a different agent' }}
			</v-btn>
		</div>
	</div>
</template>

<script>
import QuilityAPI from "@/store/API/QuilityAPI.js";

export default {
	props: {
		resetPool: { type: String, required: true },
	},

	data() {
		return {
			links: [],
			isLoading: false,
			error: "",
			selectedSME: null,
			resetPoolSet: null,
		};
	},

	mounted() {
		this.loadDFLCalendarUrl();
	},

	methods: {
		loadDFLCalendarUrl() {
			this.resetPoolSet = null
			this.isLoading = true;
			this.error = "";
			QuilityAPI.getAgentConfigsBaseshopAppointmentCalendar(this.user.AgentCode, this.resetPool)
				.then((response) => {
					this.resetPoolSet = response.meta?.resetPoolSet
					this.links = response.data.map((d) => ({
						title: d.Agent.AgentName,
						url: d.CalendarURL,
						agentCode: d.Agent.AgentCode,
					}));
					if (this.links.length === 0) {
						this.error = "Agents do not have calendar URLs set";
					}
				})
				.catch(() => {
					this.error = "Unable to get agents calendar";
				})
				.finally(() => {
					this.isLoading = false
				})
		},
		updateSME(sme) {
			this.selectedSME = sme;
		},
		confirmSME() {
			this.$amplitude.track('FIF_appointment_confirmed')
			this.$emit("sme-confirmed", {
				Agent: this.selectedSME.title,
				AgentCode: this.selectedSME.agentCode,
			});
		},
	},

	watch: {
		resetPool () {
			this.loadDFLCalendarUrl()
		}
	},

	computed: {
		selectedAgent() {
			return this.selectedSME ? this.selectedSME.title : "***";
		},
		isMobile () {
			return this.$vuetify.breakpoint.smAndDown
		},
		calendarsListDescription () {
			if (this.resetPoolSet === null || this.resetPoolSet === undefined) { return '' }
			return this.resetPoolSet
				? `Below is a list of ${this.resetPool} subject matter experts recommended by your Agency Owner.`
				: `Below is a comprehensive list of ${this.resetPool} subject matter experts.`
		},
	},
};
</script>

<style lang="scss">
	.text-danger {
		color: #ff5252;
	}

	.agent-calendars {
		width: 100%;
		max-height: 20rem;
		padding: 0.5rem 0;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		overflow: auto;
	}
</style>
