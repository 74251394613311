<template>
    <v-row class="" cols="12" width="100%">
        <slot name="meta" />

        <v-col cols="12" width="100%" v-if="contentData.title">
            <h3 class="q-title text-center">{{contentData.title}}</h3>
        </v-col>

        <slot name="Videos">
            <v-row cols="12">
                <v-col v-for="(video, key) in contentData.Videos" :key="key" cols="12" :sm="getSmallColumns" :md="getMediumColumns" class="ma-0 pa-0">
                    <QSummitWistiaPlayer :video="video" />
                </v-col>
            </v-row>
        </slot>
    </v-row>
</template>

<script>
import QSummitWistiaPlayer from '@/CMS/views/ContentBlocks/Elements/QSummitWistiaPlayer.vue'

export default {
    name: "SummitWistiaVideoGroup",

    components: { QSummitWistiaPlayer },

    props: {
        contentData: {
            type: Object,
            default() {
                return {
                    Videos: [
                        {}
                    ],
                    title: null,
                    NumColumns: 4
                }
            }
        }
    },

    computed: {
        getSmallColumns: function() {
            return Math.round(24 / this.contentData.NumColumns);
        },
        getMediumColumns: function() {
            return Math.round(12 / this.contentData.NumColumns);
        }
    },
}
</script>
