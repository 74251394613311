<template>
	<div class="gallery">
		<div class="gallery-container-wrapper">
			<div class="gallery-container">
				<div
					class="gallery-items"
				>
					<div
						v-for="(item, index) in items"
						:key="index"
						class="gallery-item__wrapper"
					>
						<div
							class="gallery-item"
							:class="{
								'gallery-item--selected': value === item,
							}"
							@click.prevent.stop="$emit('input', item)"
						>
							<slot name="item" v-bind="{ item }">
								<div class="gallery-item-title">
									{{ item.title }}
								</div>
							</slot>
							<div class="gallery-item__title">
								{{ item.title }}
							</div>
							<div class="gallery-item__play-button">
								<v-icon>fa-play</v-icon>
							</div>
							<div class="gallery-item__click-preventer" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		items: {
			type: Array,
			required: true,
			default: () => [],
		},
		visibleItems: {
			type: Number,
			default: 3,
		},
		value: {
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.gallery {
	position: relative;
	height: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
}

.gallery-container {
	overflow-y: auto;
	overflow-x: visible;
	height: calc(100% + 8px);

	margin: -8px 0;
	box-sizing: content-box;

	$color: #0c4463;
	$size: 4px;

	&::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $color;
    opacity: 0.3;
    border-radius: 2px;
  }

  scrollbar-color: $color transparent;
  scrollbar-width: thin;

	&-wrapper {
		height: 100%;
	}
}

.gallery-items {
	display: flex;
	flex-direction: column;
	transition: transform 0.3s ease-out;
}

.gallery-item {
	--accent: #14549d;
	--accent-selected: #50759e;
	--accent-hover: #1760b3;

	height: max-content;
	position: relative;
	box-sizing: border-box;
	cursor: pointer;
	z-index: 0;

	&__wrapper {
		flex: 0 0 auto;
		padding: 8px 1rem;
		position: relative;
	}

	&:last-child {
		margin-right: 0;
	}

	& > * {
		pointer-events: none;
	}

	&:hover {
		.v-icon {
			opacity: 1;
		}

		.gallery-item__play-button {
			--accent: var(--accent-hover);
		}
	}

	&::after {
		content: "";
		box-sizing: content-box;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		height: 100%;
		background: transparent;
		z-index: -1;
		transform: translateX(-50%) translateY(-50%);
		transition: background-color 0.3s ease-out;
		padding: 8px;
	}

	&--selected {
		&::after {
			background-color: var(--accent-selected);
		}
	}

	&__click-preventer {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
	}

	&__title {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 40px;
		padding: 0 1rem;
		display: flex;
		align-items: center;
		color: white;
		background: var(--accent);
		font: normal normal 300 14px/15px Montserrat !important;
	}

	&__play-button {
		position: absolute;
		left: 50%;
		top: 50%;
		width: 120px;
		height: 75px;
		display: flex;
		justify-content: center;
		align-items: center;
		transform: translateX(-50%) translateY(-50%);
		background: var(--accent);
		filter: brightness(0.8);
		.v-icon {
			color: white !important;
			opacity: 0.7;
			font-size: 1.5rem !important;
		}
	}

	h5 {
		display: none;
	}
}

.gallery-item-title {
	margin-top: 10px;
	font-size: 16px;
	font-weight: bold;
}
</style>
