<template>
    <div :class="page_classes">
        <div class="text-center" v-if="the_step == null">
            <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </div>
        <template v-if="the_step">
            <SummitStepTemplate v-if="is_visible" :step="the_step" v-on:checkrequirements="checkRequirementsEvent" v-on:skipstep="skipStepEvent">
                <template #header-right>
                    <v-btn v-if="hasPermission(['manage:Summit']) && the_step" small fab right top center class="cms-edit-btn" :to="{name : 'SummitAdmin', 'params' : {summit_step_id : the_step.ID} }">
                        <v-icon small class="mr-2">fas fa-pencil-alt</v-icon>
                        Edit
                    </v-btn>
                </template>
            </SummitStepTemplate>
            <div v-else>
                <h3 class="mt-6 ml-6">This step is not available</h3>
            </div>
        </template>
        <CompleteStepDialog v-model="showRequirementsCheck" :loading="loading_requirements" :loadingText="showRequirementsCheckText" #default="{ vBtnProps }">
            <template v-if="requirement_results.success">
                <h3 class="white--text">Sweet! Success!</h3>
                <div v-for="note in requirement_results.notes" :key="note">
                    <v-icon left color="white">fas fa-check</v-icon>
                    <strong>{{note}}</strong>
                </div>
                <div v-if="the_step && the_step.StepBadge != null && the_step.StepBadge != ''">
                    <h4 class="white--text">You earned a badge!</h4>
                    <div class="text-center">
                        <v-img :src="the_step.StepBadge" :height="200" contain class="ma-5"></v-img>
                    </div>
                </div>
                <v-btn v-bind="vBtnProps" :class="['mt-2', ...vBtnProps.class]" @click="gotoNextStep(requirement_results.nextStep)" block large>
                    Proceed Next Step
                </v-btn>
            </template>
            <template v-else-if="requirement_results.popups && requirement_results.popups.length > 0">
                <div v-for="(popup, ix) in requirement_results.popups" :key="ix">
                    <h4 class="white--text">{{popup.title}}</h4>
                    <div>
                        <p>{{popup.question}}</p>
                        <div class="d-flex justify-center">
                            <v-btn @click="answerPopup(ix, true)" v-bind="vBtnProps" :class="['mr-2', ...vBtnProps.class]">Yes!</v-btn>
                            <v-btn @click="answerPopup(ix, false)" v-bind="vBtnProps">No</v-btn>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else-if="requirement_results.notes">
                <h3 class="white--text">Uh oh! No bueno!</h3>
                <div v-for="note in requirement_results.notes" :key="note" class="d-flex justify-center">
                    <v-icon left color="white">fas fa-exclamation</v-icon>
                    <strong>{{note}}</strong>
                </div>
            </template>
        </CompleteStepDialog>
        <!-- <v-dialog v-model="showRequirementsCheck" :width="$vuetify.breakpoint.smAndDown ? '80%' : '50%'">
            <v-card color="blue" dark>
                <v-card-text dark class="text-center pa-5">
                    <div v-if="loading_requirements">
                        <img src="https://hq-image-cdn.azureedge.net/servers/local/cms_images/yeti-walking3_W7wLmv.gif" />
                        <h3 class="white--text">
                            {{showRequirementsCheckText}}
                            <v-progress-circular indeterminate size="20"></v-progress-circular>
                        </h3>
                    </div>
                    <div v-else-if="requirement_results.success">
                        <h3 class="white--text">Sweet! Success!</h3>
                        <div v-for="note in requirement_results.notes">
                            <v-icon left>fas fa-check</v-icon>
                            <strong>{{note}}</strong>
                        </div>
                        <div v-if="the_step && the_step.StepBadge != null && the_step.StepBadge != ''">
                            <h4 class="white--text">You earned a badge!</h4>
                            <div class="text-center">
                                <v-img :src="the_step.StepBadge" :height="200" contain class="ma-5"></v-img>
                            </div>
                        </div>
                        <v-btn color="primary" @click="gotoNextStep(requirement_results.nextStep)" block large>Proceed Next Step</v-btn>
                    </div>
                    <div v-else-if="requirement_results.popups && requirement_results.popups.length > 0">
                        <div v-for="(popup, ix) in requirement_results.popups" :key="ix">
                            <h4 class="white--text">{{popup.title}}</h4>
                            <div>
                                <p>{{popup.question}}</p>{{popup.answer}}
                                <v-btn @click="answerPopup(ix, true)" class="mr-2" color="primary">Yes!</v-btn>
                                <v-btn @click="answerPopup(ix, false)">No</v-btn>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="requirement_results.notes">
                        <h3 class="white--text">Uh oh! No bueno!</h3>
                        <div v-for="note in requirement_results.notes">
                            <v-icon left>fas fa-exclamation</v-icon>
                            <strong>{{note}}</strong>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog> -->
    </div>
</template>
<script>
import SummitStepTemplate from '@/views/Summit/SummitStepTemplate.vue';
import SummitMilestoneTemplate from '@/views/Summit/SummitMilestoneTemplate.vue';
import SummitCheckpointTemplate from '@/views/Summit/SummitCheckpointTemplate.vue';
import FullWidthText from '@/CMS/views/ContentBlocks/FullWidthText.vue';
import WistiaVideoGroup from '@/CMS/views/ContentBlocks/SummitWistiaVideoGroup.vue';
import SummitNav from '@/store/Models/SummitNav'
import { EventBus } from '@/event-bus';
import QuilityAPI from '@/store/API/QuilityAPI.js'
import CompleteStepDialog from '../../components/summit/dialogs/CompleteStepDialog.vue'

export default {
    props: ['page', 'slug', 'noTemplate'],
    data: () => ({
        loaded: false,
        the_step: null,
        showRequirementsCheck: false,
        showRequirementsCheckText: 'Let me check with the sherpas...',
        loading_requirements: false,
        requirement_results: { 'success': false },
        fireworks: false
    }),
    mounted: function() {
        if (typeof this.$route.params.step_slug != 'undefined') {
            this.loadStep(this.$route.params.step_slug)
        }
        if (typeof this.slug != 'undefined') {
            this.loadStep(this.slug)
        }
    },
    computed: {
        'page_classes': function() {
            if (this.the_step == null) { return '' }
            var classes = []; //this.the_step.styles.slice();
            classes.push('cms')
            classes.push('full-height')
            return classes.join(' ')
        },
        'is_visible': function() {
            return this.the_step.EnabledInd
        },
    },
    methods: {
        checkRequirementsEvent: function() {
            this.showRequirementsCheckText = 'Let me check with the sherpas...'
            this.checkRequirements();
        },

        skipStepEvent: function() {
            this.fireworks = false
            this.showRequirementsCheckText = 'Gonna skip this step for now....'
            this.showRequirementsCheck = true
            this.loading_requirements = true
            this.requirement_results = { 'success': false }
            var g = this
            QuilityAPI.skipStep(this.the_step)
                .then(function(json) {
                    g.showRequirementsCheck = false
                    g.loading_requirements = false
                    g.refreshSummitNav();
                    g.$router.push(json.data.Url)
                })
                .catch(function(err) {
                    g.loaded = true;
                });
        },

        checkRequirements: function(bypass_popups) {
            this.fireworks = false
            if (typeof bypass_popups == 'undefined' || bypass_popups == null) {
                bypass_popups = false
            }
            this.showRequirementsCheck = true
            this.loading_requirements = true
            this.requirement_results = { 'success': false }
            var g = this
            QuilityAPI.checkStepRequirements(this.the_step, bypass_popups)
                .then(function(json) {
                    json.popups.forEach(function(p, ix) {
                        json.popups[ix].answer = null
                    })
                    if (json.success === true) {
                        g.user.Agent.CurrentSummitBadge = {
                            'full': g.the_step.StepBadge,
                            'micro': g.the_step.StepBadgeMicro,
                        }
                        //update the nav to show this is now complete for the agent. Might as well do a full refresh.
                        g.refreshSummitNav()
                    }
                    setTimeout(function() {
                        g.requirement_results = json
                        g.loading_requirements = false;
                        g.showRequirementsCheckText = "Done checking!"
                    }, 1000);
                })
                .catch(function(err) {
                    g.loaded = true;
                });
        },
        loadStep: function(slug) {
            var g = this
            this.the_step = null;
            QuilityAPI.getStepWithSlug(slug)
                .then(function(json) {
                    g.the_step = json.data;
                    if (!g.the_step.EnabledInd) {
                        g.$router.push("/404")
                    }
                })
                .catch(function(err) {
                    g.showError("Ya can't skip to this step!")
                    g.the_step = {}
                    g.loaded = true;
                });
        },
        blockClasses(block, other) {
            if (typeof block.styles != 'object') {
                this.$set(block, 'styles', [])
            }
            var classes = [
                ...block.styles,
                block.type,
                other
            ]
            return classes.join(' ')
        },
        answerPopup(ix, value) {
            this.requirement_results.popups[ix].answer = value
            this.checkAllPopups();
        },
        checkAllPopups() {
            var answered = true;
            this.requirement_results.popups.forEach(function(popup) {
                if (popup.answer == null) {
                    answered = false
                }
            })
            if (!answered) {
                return;
            }
            var valid = true;
            this.requirement_results.popups.forEach(function(popup) {
                if (popup.answer == false) {
                    valid = false
                }
            })
            if (valid) {
                this.showRequirementsCheckText = 'Checking one more time!'
                this.checkRequirements(true); // passes the bypass_popups flag
            } else {
                this.showRequirementsCheck = false
            }
        },
        gotoNextStep(step) {
            this.fireworks = false
            this.showRequirementsCheck = false;
            this.showRequirementsCheckText = 'Let me check with the sherpas...'
            this.loading_requirements = false;
            this.requirement_results = { 'success': false };
            this.$router.push(this.the_step.NextStepUrl)
        },
        refreshSummitNav() {
            QuilityAPI.getSummitNav().then(function(json) {
                SummitNav.create({ data: json.data })
            })
        }
    },
    watch: {
        '$route.params.step_slug': function(newV, oldV) {
            this.fireworks = false
            this.the_step = null
            if (typeof newV != 'undefined') {
                this.loadStep(newV);
            }
        },
        'requirement_results.success': function(newV) {
            if (newV === true) {
                this.fireworks = true
            } else {
                this.fireworks = false
            }
        },
        'fireworks': function(newV) {
            //allows for random celebration animations.
            var celebrations = [
                'confetti',
                'fireworks'
            ]
            var ix = Math.round(Math.random());
            if (newV === true && this.the_step && this.the_step.StepType == 'Milestone') {
                EventBus.$emit('background-animation', celebrations[ix] + '-animation ' + celebrations[ix] + '-overlay');
            } else {
                EventBus.$emit('background-animation', null);
            }
        }
    },
    components: {
        SummitStepTemplate,
        FullWidthText,
        WistiaVideoGroup,
        SummitMilestoneTemplate,
        SummitCheckpointTemplate,
        CompleteStepDialog,
        //AccessBlocked
    },
}
</script>
<style scoped>
.cms-edit-btn {
    width: auto !important;
    padding: 0 15px !important;
}

.cms-edit-btn {
    padding-left: 15px;
}
</style>
