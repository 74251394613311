<template>
    <QSummitStepBackgroundBottom>
        <QSummitStepBackgroundBottomCenter class="q-summit-step-background-bottom__center" />        
    </QSummitStepBackgroundBottom>
</template>

<script>
import QSummitStepBackgroundBottomCenter from "./bottom/QSummitStepBackgroundBottomCenter.vue";
import QSummitStepBackgroundBottom from '../_base/QSummitStepBackgroundBottom.vue'

export default {
    components: { QSummitStepBackgroundBottom, QSummitStepBackgroundBottomCenter }
}
</script>

<style lang="scss" scoped>
.q-summit-step-background-bottom {
    &__center {
        transform: translateX(-50%) translateY(10px);
    }
}
</style>