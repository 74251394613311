<template>
    <div class="q-summit-step-background checkpoint">
        <div class="q-summit-step-background__image">
            <QSummitStepBackgroundBottom class="q-summit-step-background__bottom" />
            <QSummitStepBackgroundClouds class="q-summit-step-background__clouds" />
        </div>

        <slot />
    </div>
</template>

<script>
import QSummitStepBackgroundClouds from './QSummitStepBackgroundClouds.vue'
import QSummitStepBackgroundBottom from './QSummitStepBackgroundBottom.vue'

export default {
    components: { QSummitStepBackgroundClouds, QSummitStepBackgroundBottom },
}
</script>
<style lang="scss">
    .q-summit-step-background.checkpoint {
        h1, h2, h3, h4, h5, h6 {
            color: white;
        }
        li > p {
            margin-bottom: 0;
        }
    }
</style>
<style lang="scss" scoped>
    .q-summit-step-background {
        position: relative;
        overflow: hidden;
        height: 100%;
        z-index: 1;
        background: linear-gradient(0deg, #7e628c, #06204b);
        color: white;

        &__clouds {
            position: absolute;
            z-index: -1;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        &__bottom {
            position: absolute;
            z-index: -1;
            bottom: 0;
            width: 100%;
            height: 100%;
        }
    }
</style>