<template>
    <div>
        <h3 class="py-8">Quility Custom Icons</h3>
        <div v-if="qIcons.length === 0">No icons loaded.</div>
        <div v-else class="custom-icons-row">
            <div v-for="qIcon in qIcons" :key="qIcon" class="custom-icon">
                <v-icon large :class="[qIcon, 'demo-icon']">
                icon-{{ qIcon }}
            </v-icon>
                <p >icon-{{ qIcon }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import config from './config.json';

export default {
    data() {
        return {
            qIcons: [],
        };
    },
    mounted() {
        this.loadIcons();
    },
    methods: {
        loadIcons() {
            try {
                if (config && Array.isArray(config.glyphs)) {
                    // Extract "css" values from the loaded config.json file
                    this.qIcons = config.glyphs.map(qIcon => {
                        console.log('Loaded icon:', qIcon.css);
                        return qIcon.css;
                    });
                } else {
                    console.error('Invalid or missing glyphs array in config.json file.');
                }
            } catch (error) {
                console.error('Error loading icons:', error);
            }
        },
    },
};
</script>

<style scoped>
/* Add your icon font styles if needed */
.demo-icon {
    font-family: 'fontello';
    font-size: 40px !important;
    color:#000;
    /* Adjust the size as needed */
}

.custom-icons-row {
    display: flex;
    flex-wrap: wrap;
    max-width:95%;
    margin:0 auto;
}
.custom-icon {
    padding:10px;
    text-align:center;
    width:22%;
}
</style>
