<template>
    <div class="d-flex flex-wrap align-center mt-4" style="width: 100%">
        <slot name="try-again" />
        <v-spacer />
		<v-btn class="mx-1 my-1" outlined depressed color="q_blue_1" @click="confirmAppointment" v-if="!Unconfirmed">
			{{ isMobile ? 'Confirm appointment' : 'Confirm that your appointment is set' }}
		</v-btn>

        <v-btn class="mx-1 my-1" color="q_blue_1" depressed target="_blank" :href="QRSCalendarLink" @click="Unconfirmed = false" v-if="Unconfirmed">
            <v-icon left>icon-events</v-icon>
            <v-divider
                class="mx-1"
                vertical
            />
            Set an appointment
        </v-btn>
    </div>
</template>

<script>
export default {
    data () {
        return {
            Unconfirmed: true,
			QRSCalendarLink: 'https://qfa.zohobookings.com/#/customer/retirementreview',
        }
    },
	computed: {
		isMobile () {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
	methods: {
        confirmAppointment () {
            this.$emit('qrs-confirmed')
        },
    },
}
</script>
