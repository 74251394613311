<template>
    <div class="cms">
        <div v-if="the_form && noTemplate === true">
            <div v-if="hasPermission(['manage:Forms'])">
                <v-btn fab absolute right center class="fml-edit-btn" :to="{name : 'FMLEditor', 'params' : {form_id : the_form.ID} }">
                    <v-icon small class="mr-2">fas fa-pencil-alt</v-icon>
                </v-btn>
            </div>
            <div v-if="show_response">
                <v-card-title>
                    <h3>Quiz Results</h3>
                    <div class="full-width px-5">
                        <p class="body-1" v-html="the_form.FormConfig.response"></p>
                    </div>
                </v-card-title>
                <v-card-text>
                    <v-row class="d-flex justify-center align-center pb-0">
                        <div style="display:inline-block; max-width:80%;">
                            <p class="text-center mb-0 pb-0">Your score:</p>
                            <h1 class="text-center font-weight-bold mt-0 mb-3 py-0" style="color:#0083eb;  font-size:70px;"> {{ the_form.FormResponse.ResponseScore }}%</h1>
                            <div class="d-flex  full-width justify-center">
                                <div class="px-4" style="border-top: 1px solid #999; display:inline-block; margin:0 auto" v-if="the_form.FormResponse.PassingInd" v-html="the_form.FormConfig.passResponse"></div>
                                <div class="px-4" style="border-top: 1px solid #999;" v-else v-html="the_form.FormConfig.failResponse"></div>
                            </div>
                        </div>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="q_agency_1" class="mr-8" text right @click="resetForm">Reset Quiz</v-btn>
                </v-card-actions>
            </div>
            <div v-else>
                <h2>{{the_form.FormTitle}}</h2>
                <p v-if="the_form.FormContent">{{the_form.FormContent}}</p>
                <div v-for="(set, key) in the_form.FormQuestionSets" :key="'question_set_' + set.ID" :class="blockClasses(set.QuestionSetConfig, 'my-0')">
                    <fml-question-set :question-set="set" v-model="the_form.FormResponse" :last-set="key == the_form.FormQuestionSets.length - 1" v-on:submitted="showResponseText"></fml-question-set>
                </div>
            </div>
        </div>
        <div v-else-if="the_form && is_visible" :is="the_form.FormConfig.template" :content-data="the_form">
            <slot></slot>
            <div v-if="hasPermission(['manage:Forms'])">
                <v-btn fab absolute right center class="fml-edit-btn" :to="{name : 'FMLEditor', 'params' : {form_id : the_form.ID} }">
                    <v-icon small class="mr-2">fas fa-pencil-alt</v-icon>
                </v-btn>
            </div>
            <div v-if="show_response">
                <v-card style="min-height:80vh;">
                    <v-card-title>
                        <h3>Quiz Results</h3>
                        <div class="full-width px-5">
                            <p class="body-1" v-html="the_form.FormConfig.response"></p>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-row class="d-flex justify-center align-center pb-0" style="min-height: calc(80vh - 250px);">
                            <div style="display:inline-block; max-width:80%;">
                                <p class="text-center mb-0 pb-0">Your score:</p>
                                <h1 class="text-center font-weight-bold mt-0 mb-3 py-0" style="color:#0083eb;  font-size:70px;"> {{ the_form.FormResponse.ResponseScore }}%</h1>
                                <div class="d-flex  full-width justify-center">
                                    <div class="px-4" style="border-top: 1px solid #999; display:inline-block; margin:0 auto" v-if="the_form.FormResponse.PassingInd" v-html="the_form.FormConfig.passResponse"></div>
                                    <div class="px-4" style="border-top: 1px solid #999;" v-else v-html="the_form.FormConfig.failResponse"></div>
                                </div>
                            </div>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="q_agency_1" class="mr-8" text right @click="resetForm">Reset Quiz</v-btn>
                    </v-card-actions>
                </v-card>
            </div>
            <div v-else>
                <h2>{{the_form.FormTitle}}</h2>
                <p v-if="the_form.FormContent">{{the_form.FormContent}}</p>
                <v-stepper v-model="step">
                    <v-stepper-header>
                        <v-stepper-step v-for="(set, key) in the_form.FormQuestionSets" :step="key + 1" :complete="step > (key + 1)" :key="'question_set_' + set.ID">
                            Step {{ key + 1 }}
                        </v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items>
                        <v-stepper-content v-for="(set, key) in the_form.FormQuestionSets" :step="key + 1" :key="'question_set_' + set.ID">
                            <fml-question-set :question-set="set" v-model="the_form.FormResponse" :last-set="key == the_form.FormQuestionSets.length - 1" v-on:submitted="showResponseText" v-on:saved="step++" form-type="Quiz"></fml-question-set>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </div>
        </div>
        <v-progress-linear v-if="!the_form" indeterminate color="primary"></v-progress-linear>
        <div v-if="the_form && !is_visible">
            <h1 class="mt-6 ml-6">Page not found</h1>
        </div>
        <v-expansion-panels v-if="hasRole(['SuperAdmin', 'Staff'])" class="mt-8">
            <v-expansion-panel v-if="the_form">
                <v-expansion-panel-header>Debug</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <pre v-if="the_form.FormResponse">{{ JSON.stringify(the_form.FormResponse, null, 2) }}</pre>
                    <hr>
                    <pre>{{ JSON.stringify(the_form, null, 2) }}</pre>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>
<script>
import Default from '@/CMS/views/PageTemplates/default.vue';
import Marketing from '@/CMS/views/PageTemplates/marketing.vue';
import QuilityTech from '@/CMS/views/PageTemplates/quility-tech.vue';
import Resources from '@/CMS/views/PageTemplates/resources.vue';
import Training from '@/CMS/views/PageTemplates/training.vue';
import News from '@/CMS/views/PageTemplates/news.vue';
import Contests from '@/CMS/views/PageTemplates/contests.vue';
import Events from '@/CMS/views/PageTemplates/events.vue';
import Benefits from '@/CMS/views/PageTemplates/benefits.vue';
import Zendesk from '@/CMS/views/PageTemplates/zendesk.vue';
import AgencyBuilding from '@/CMS/views/PageTemplates/agency-building.vue';
import External from '@/CMS/views/PageTemplates/external.vue';
import Carrier from '@/CMS/views/PageTemplates/carrier.vue';
import CarrierResources from '@/CMS/views/PageTemplates/carrier-resources.vue';
import { EventBus } from '@/event-bus';

import FmlQuestionSet from './FmlQuestionSet.vue';
/*
import Spacer from '@/CMS/views/ContentBlocks/Spacer.vue';
import CallToAction from '@/CMS/views/ContentBlocks/CallToAction.vue';
import FullWidthText from '@/CMS/views/ContentBlocks/FullWidthText.vue';
import ZendeskText from '@/CMS/views/ContentBlocks/ZendeskText.vue';
import TwoCol5050 from '@/CMS/views/ContentBlocks/TwoCol5050.vue';
import TwoCol2575 from '@/CMS/views/ContentBlocks/TwoCol2575.vue';
import TwoCol7525 from '@/CMS/views/ContentBlocks/TwoCol7525.vue';
import ThreeCol255025 from '@/CMS/views/ContentBlocks/ThreeCol255025.vue';
import ThreeCol333333 from '@/CMS/views/ContentBlocks/ThreeCol333333.vue';
import FourCol25252525 from '@/CMS/views/ContentBlocks/FourCol25252525.vue';
import CardStyles from '@/CMS/views/ContentBlocks/CardStyles.vue';
import ButtonGroup from '@/CMS/views/ContentBlocks/ButtonGroup.vue';
import CardGroup from '@/CMS/views/ContentBlocks/CardGroup.vue';
import WistiaVideoGroup from '@/CMS/views/ContentBlocks/WistiaVideoGroup.vue';
import IframeGroup from '@/CMS/views/ContentBlocks/IframeGroup.vue';
import Banner from '@/CMS/views/ContentBlocks/Banner.vue';
import ScriptEmbedGroup from '@/CMS/views/ContentBlocks/ScriptEmbedGroup.vue';
import CarrierContactInfo from '@/CMS/views/ContentBlocks/CarrierContactInfo.vue';
import CarrierProducts from '@/CMS/views/ContentBlocks/CarrierProducts.vue';
import CarrierLogo from '@/CMS/views/ContentBlocks/CarrierLogo.vue';
import AcknowledgeCheckBox from '@/CMS/views/ContentBlocks/AcknowledgeCheckBox.vue';
import Accordion from '@/CMS/views/ContentBlocks/Accordion.vue';
import DataTableGroup from '@/CMS/views/ContentBlocks/DataTableGroup.vue';
import SummitCalculator from "@/CMS/views/ContentBlocks/SummitCalculator";
*/

import QuilityAPI from '@/store/API/QuilityAPI.js'
//import Page from '@/store/Models/Page'

export default {
    props: {
        form: {},
        slug: {},
        noTemplate: {
            type: Boolean
        }
    },
    data: () => ({
        loaded: false,
        the_form: null,
        show_response: false,
        reload_counter: 0,
        step: 1,
    }),
    mounted: function() {
        if (typeof this.form != 'undefined') {
            let f = Object.assign({}, this.form);
            g.$set(g, "the_form", f);
            return
        }
        if (typeof this.$route.params.form_slug != 'undefined') {
            this.loadForm(this.$route.params.form_slug)
            return
        }
        if (typeof this.slug != 'undefined' && this.slug != null) {
            this.loadForm(this.slug)
            return
        }
        var g = this
    },
    computed: {
        'is_visible': function() {
            if (this.the_form.EnabledInd == true || this.hasPermission('manage:Forms'))
                return true
            return false
        }
    },
    methods: {
        resetForm: function() {
            this.reload_counter = this.reload_counter + 1;
            this.$router.push({ query: {} })
            //this.loadForm(this.slug)
            this.show_response = false;
            this.step = 1;
            this.reloadForm();
        },
        showResponseText: function(respData) {
            this.show_response = true;
        },
        loadForm: function(slug) {
            var g = this
            g.loaded = false
            g.$set(g, "the_form", null);
            var response_id = this.$route.query.form_response_id ? this.$route.query.form_response_id : 'new';
            QuilityAPI.getFormWithSlug(slug, response_id)
                .then(function(data) {
                    g.$set(g, "the_form", data);
                    g.$forceUpdate();
                    g.$nextTick(function() { g.loaded = true })
                })
                .catch(function(err) {
                    g.showError("Whoops! Can't find that form!")
                });
            g.hasChanges = false;
        },
        //we just need a blank template.
        reloadForm: function() {
            var g = this
            g.loaded = false
            g.loading = true
            var id = this.the_form.ID
            this.the_form = null;
            QuilityAPI.getForm(id)
                .then(function(data) {
                    g.$set(g, "the_form", data);
                    g.$forceUpdate();
                    g.$nextTick(function() { g.loaded = true })
                })
                .catch(function(err) {
                    g.loading = false
                    g.showError("Whoops! Can't find that form!")
                });
            g.hasChanges = false;
        },
    },
    watch: {
        '$route.params.form_slug': function(newV, oldV) {
            if (typeof newV != 'undefined') {
                this.loadForm(newV);
            }
        },
        'slug': function(newV, oldV) {
            if (typeof newV != 'undefined' && newV != null) {
                this.loadForm(newV);
            }
        },
        loaded(val) {
            if (val) {
                if (this.$route.hash) {
                    const element = document.querySelector(this.$route.hash)

                    if (element) {
                        const top = element.getBoundingClientRect().top + window.pageYOffset - 100
                        window.scrollTo({ top })
                    }
                }
            }
        }
    },
    components: {
        Default,
        News,
        Contests,
        Resources,
        Marketing,
        Events,
        Training,
        Benefits,
        Zendesk,
        External,
        Carrier,
        CarrierResources,

        FmlQuestionSet
    },
}

</script>
<style scoped>
.fml-edit-btn {
    width: auto !important;
    padding: 0 15px !important;
}

.fml-edit-btn {
    padding-left: 15px;
}

</style>
