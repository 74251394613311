
<template>
    <v-container fluid class="px-0 py-8 site-width">
		<v-progress-linear v-if="!cmsPageSlug && loading" indeterminate />

        <cms-page
			v-if="cmsPageSlug"
			:key="cmsPageSlug"
			:slug="cmsPageSlug"
			@page-loaded="cmsPageLoaded = $event"
		/>

		<div v-if="!isOptedIn && !isFinished" class="calendar-dialog__btn-wrapper">
			<v-btn
				v-if="cmsPageLoaded === CMS_BLOCKS_SLUG.noOptedIn"
				class="calendar-dialog__btn" elevation="0"
				@click="joinChallenge"
			>
				<v-progress-circular v-if="saving" class="mr-2" left size="24" indeterminate />
				Join the Challenge
			</v-btn>
		</div>

		<div
			v-else-if="doShowCalendar"
			class="calendar container"
			:class="$vuetify.breakpoint.xs && 'calendar--mobile'"
		>
			<div v-if="isOptedIn && !hasLastDayGoal" class="d-flex align-center mb-2">
				<v-slider
					v-model="streakCounter"
					:max="challengeDuration"
					class="mr-4"
					color="#FFA580"
					track-color="#EEE6DD"
					readonly
					hide-details
				/>
					<v-tooltip top>
						<template #activator="{ attrs, on }">
							<div v-bind="attrs" v-on="on">
								<span>{{ streakCounter }}</span>
								<v-icon color="#ff5951" class="ml-1">fa-solid fa-fire</v-icon>
								<v-icon color="#ffa580" class="ml-1 mr-2">fa-solid fa-smile-beam</v-icon>
							</div>
						</template>
						Streaks show the number of consecutive days you've added 3 or more gratitudes
					</v-tooltip>
			</div>

			<div class="calendar__loader-container">
				<v-progress-linear v-if="loading" indeterminate />
			</div>
			<div class="calendar__header">
				<v-btn class="ma-2" icon @click="prevCalendar">
					<v-icon>fas fa-chevron-left</v-icon>
				</v-btn>
				<div class="calendar__header-month">{{ currentMonth }}</div>
				<v-btn class="ma-2" icon @click="nextCalendar">
					<v-icon>fas fa-chevron-right</v-icon>
				</v-btn>
			</div>

			<v-calendar
				ref="calendar"
				:key="calendarValue"
				v-model="calendarValue"
				type="month"
				:class="(hasError || loading) && 'calendar--disabled'"
				:short-weekdays="$vuetify.breakpoint.mdAndUp ? false : true"
				:show-month-on-first="false"
				:events="events"
				:event-more="false"
				@click:day="showDayEvents"
			>
				<template #day-label="{ day }">
					<span class="day-label">{{ day }}</span>
				</template>
				<template #event>
					<span class="event-circle" />
				</template>
			</v-calendar>
			<div class="mt-8 body-2">
				<strong>Acknowledgment</strong>: By using this platform and submitting content, you acknowledge and agree that Quility and its staff will be able to view your journal, gratitudes, and other posts and use them for their own commercial purposes.
			</div>
		</div>

		<v-dialog
			v-model="showForm"
			:fullscreen="$vuetify.breakpoint.xs"
			width="100%"
			max-width="800px"
			:persistent="currentDialogStep === 2"
			@click:outside="currentDialogStep === 1 && close()"
			@keydown.esc="currentDialogStep === 1 && close()"
		>
			<GratitudesJournalCelebrations v-if="doShowCelebrations" />

			<v-card class="calendar-dialog">
				<v-card-title class="calendar-dialog__card-title">
					<span class="calendar-dialog__title">MY GRATITUDES</span>
					<span class="calendar-dialog__date">{{ selectedDateString }}</span>
				</v-card-title>

				<v-stepper id="stepper" v-model="currentDialogStep">
					<v-stepper-content step="1">
						<v-row v-if="!selectedDateGratitudes.gratitudes?.length && !selectedDateGratitudes.hasAnalogGratitudes">
							<v-col>
								<span class="calendar-dialog__note">No gratitudes entries yet</span>
							</v-col>
						</v-row>

						<template v-if="selectedDateGratitudes.gratitudes?.length">
							<v-row
								v-for="({ field_title, field_gratitude }, idx) in selectedDateGratitudes.gratitudes"
								:key="idx"
								dense
							>
								<v-col>
									<p v-if="field_title.AnswerContent" class="calendar-gratitude__title">{{ field_title.AnswerContent }}</p>
									<p class="calendar-gratitude__text">{{ field_gratitude.AnswerContent }}</p>
								</v-col>
								<v-col v-if="idx < selectedDateGratitudes.gratitudes.length - 1" cols="12">
									<v-divider class="calendar-gratitude__divider" />
								</v-col>
							</v-row>
						</template>

						<v-row v-if="selectedDateGratitudes.hasAnalogGratitudes">
							<v-col v-if="selectedDateGratitudes.gratitudes?.length" cols="12">
								<v-divider class="calendar-gratitude__divider" />
							</v-col>
							<v-col>
								<v-checkbox
									hide-details color="#d8980e" disabled class="mt-0"
									v-model="selectedDateGratitudes.hasAnalogGratitudes"
									label="I recorded 3 or more gratitudes in my personal journal today" />
							</v-col>
						</v-row>

						<v-row class="flex-grow-0">
							<v-col class="d-flex mt-12">
								<v-btn class="calendar-dialog__btn" text color="#FFA580" @click="close">Close</v-btn>
								<v-btn class="calendar-dialog__btn ml-auto" elevation="0" @click="editDateGratitudes">
									<span class="calendar-dialog__btn-icon">+</span>
									<v-divider class="mx-2" color="white" vertical />
									Add/Edit
								</v-btn>
							</v-col>
						</v-row>
					</v-stepper-content>

					<v-stepper-content step="2">
						<v-form
							ref="form"
							v-model="validForm"
							class="calendar-form"
							:disabled="saving"
						>
							<template v-if="selectedDateGratitudes.gratitudes?.length">
								<v-row>
									<v-col class="font-weight-black">Edit your gratitudes</v-col>
								</v-row>
								<v-row
									v-for="({ field_title, field_gratitude, field_isShareable }, idx) in selectedDateGratitudes.gratitudes"
									:key="idx"
									dense
								>
									<v-col>
										<v-text-field
											class="calendar-form__text-field" dense outlined hide-details color="#FFA580"
											v-model="field_title.AnswerContent"
											placeholder="Title"
											@change="field_title.AnswerContent = $event.trim()" />
										<v-textarea
											class="calendar-form__text-field mt-2" dense outlined hide-details="auto" :rows="3" color="#FFA580"
											v-model="field_gratitude.AnswerContent"
											:rules="[form_rules.required]"
											placeholder="I feel gratitude for:"
											@change="field_gratitude.AnswerContent = $event.trim()" />
										<v-checkbox
											class="mt-2 ml-2" hide-details color="#FFA580"
											v-model="field_isShareable.AnswerContent"
											label="Allow my gratitude to be shared" />
									</v-col>
									<v-col cols="12">
										<v-divider class="calendar-gratitude__divider" />
									</v-col>
								</v-row>
							</template>

							<v-row dense>
								<v-col cols="12" class="font-weight-black">New gratitudes</v-col>
								<template v-for="(entry, idx) in newGratitudes">
									<v-col :key="idx + '-fields'" cols="12">
										<v-text-field
											class="calendar-form__text-field mt-2" dense hide-details outlined color="#FFA580"
											v-model="entry.title"
											label="Title"
											@change="entry.title = $event.trim()" />
										<v-textarea
											class="calendar-form__text-field mt-4" hide-details outlined :rows="5" auto-grow color="#FFA580"
											v-model="entry.gratitude"
											label="I feel gratitude for:"
											@change="entry.gratitude = $event.trim()" />
										<div class="mt-4 d-flex align-start justify-space-between">
											<v-checkbox
												class="mt-0 ml-2" hide-details color="#FFA580"
												v-model="entry.isShareable"
												label="Allow my gratitude to be shared" />
											<v-btn v-if="newGratitudes.length > 1" class="calendar-dialog__btn ml-6" icon @click="removeGratitude(idx)">
												<v-icon color="#FFA580">fas fa-trash-alt</v-icon>
											</v-btn>
										</div>
									</v-col>
									<v-col :key="idx + '-divider'" v-if="idx < newGratitudes.length - 1" cols="12">
										<v-divider class="calendar-gratitude__divider" />
									</v-col>
								</template>
								<v-col cols="12" class="mt-6 text-right">
									<v-btn
										class="calendar-dialog__btn ml-auto" elevation="0"
										:small="$vuetify.breakpoint.xs"
										:disabled="saving"
										@click="addNewGratitude"
									>
										<span class="calendar-dialog__btn-icon">+</span>
										<v-divider class="mx-2" color="white" vertical />
										ADD ANOTHER GRATITUDE
									</v-btn>
								</v-col>
							</v-row>

							<v-row class="mt-6">
								<v-col>
									<p class="font-weight-black">Analog gratitudes</p>
									<v-checkbox
										class="ml-2" hide-details color="#FFA580"
										v-model="selectedDateGratitudes.hasAnalogGratitudes"
										label="I recorded 3 or more gratitudes in my personal journal today" />
								</v-col>
							</v-row>

							<v-row class="flex-grow-0">
								<v-col cols="12" class="mt-4">
									<v-alert v-if="warning" type="warning">
										{{ warning }}
									</v-alert>
								</v-col>
								<v-col class="d-flex mt-2">
									<v-btn class="calendar-dialog__btn" text color="#FFA580" :disabled="saving" @click="cancel">Cancel</v-btn>
									<v-btn class="calendar-dialog__btn ml-auto" :disabled="!submitEnabled || saving" elevation="0" @click="save">
										<v-progress-circular v-if="saving" class="mr-2" left size="24" indeterminate />
										Save
									</v-btn>
								</v-col>
							</v-row>
						</v-form>
					</v-stepper-content>
				</v-stepper>
			</v-card>
		</v-dialog>
    </v-container>
</template>

<script>
import moment from 'moment'
import { cloneDeep } from 'lodash'
import { groupBy } from 'lodash'

import QuilityAPI from '@/store/API/QuilityAPI'
import { EventBus } from '@/event-bus'
import CmsPage from '@/CMS/views/index.vue'
import GratitudesJournalCelebrations from './GratitudesJournalCelebrations.vue'

const GRATITUDES_CHALLENGE_TITLE = '30-Day Gratitude Challenge + Journal'

const makeNewGratitudeFormAnswer = ({ title, gratitude, isShareable, submittedDate }) => ({
	field_title: {
		AnswerContent: title,
		Alias: 'title',
		AnswerDataType: 'String',
		FormQuestionID: null,
		Key: 'title',
		Label: 'Answer',
		Title: 'Title',
	},
	field_gratitude: {
		AnswerContent: gratitude,
		Alias: 'gratitude',
		AnswerDataType: 'String',
		FormQuestionID: null,
		Key: 'gratitude',
		Label: 'Answer',
		Title: 'Gratitude',
	},
	field_isShareable: {
		AnswerContent: isShareable,
		Alias: 'isShareable',
		AnswerDataType: 'Boolean',
		FormQuestionID: null,
		Key: 'isShareable',
		Label: 'Answer',
		Title: 'Allow my gratitude to be shared',
	},
	field_submittedDate: {
		AnswerContent: submittedDate,
		Alias: 'submittedDate',
		AnswerDataType: 'String',
		FormQuestionID: null,
		Key: 'submittedDate',
		Label: 'Answer',
		Title: 'SubmittedDate',
	},
})

const getNewGratitudeTemplate = () => ({
	title: '',
	gratitude: '',
	isShareable: false,
})

export default {
	name: 'GratitudesJournal',
    components: {
		CmsPage,
		GratitudesJournalCelebrations,
	},
    data () {
        return {
			CMS_BLOCKS_SLUG: {
				optedIn: 'gratitudes-journal-opted-in',
				noOptedIn: 'gratitudes-journal-no-opted-in',
				finished: 'gratitudes-journal-challenge-complete',
			},
			FML_FORM_SLUG: 'gratitudes-journal-form',
			challengeDuration: 30,
			minGoal: 3,
			overachievedGoal: 6,

			warning: '',
			cmsPageLoaded: '',
			saving: false,
            loading: false,
            hasError: false,
			calendarValue: moment().format('YYYY-MM-DD'),
			calendarValueTimer: null,
			currentMonth: '',
			selectedDate: '',
			validForm: false,
			showForm: false,
            currentDialogStep: 1,
			fmlFormData: null,
			fmlFormResponses: [],
			formResponseTemplate: null,
			selectedDateGratitudes: { gratitudes: [], hasAnalogGratitudes: false },
			newGratitudes: [getNewGratitudeTemplate()],
			isSelectedDateGratitudesEdited: false,
			doShowCelebrations: false,
			celebrationsTimer: null,
			hasLastDayGoal: null,
        }
    },
	async created () {
		this.getCurrentMonth(this.calendarValue)
		this.setCalendarValueTimer()
		if (this.isFinished) {
			await this.loadFormData()
			this.checkBadge()
		}
		if (this.isOptedIn) {
			await this.loadFormData()
		} 
		this.checkLastDayGoal()
	},
	beforeDestroy () {
		clearTimeout(this.celebrationsTimer)
		clearTimeout(this.calendarValueTimer)
	},
    computed: {
		gratitudeChallengeConfig () {
			const configValue = this.user.config?.GratitudeChallenge?.ConfigValue
			if (!configValue?.length) { return }
			return configValue.find(entry => entry?.title === GRATITUDES_CHALLENGE_TITLE)
		},
		isOptedIn () {
			if (!this.gratitudeChallengeConfig) { return false }
			return moment().isBetween(
				this.gratitudeChallengeConfig.startDate, this.gratitudeChallengeConfig.endDate, 'day', '[]'
			)
		},
		hasBadge () {
			return this.gratitudeChallengeConfig?.hasBadge || false
		},
		isFinished () {
			if (!this.gratitudeChallengeConfig) { return false }
			return moment().isAfter(this.gratitudeChallengeConfig.endDate, 'day')
		},
		isLastDay () {
			if (!this.gratitudeChallengeConfig) { return false }
			return moment().isSame(this.gratitudeChallengeConfig.endDate, 'day')
		},
		cmsPageSlug () {
			if (this.hasLastDayGoal === null) { return '' }
			if (this.isFinished || this.hasLastDayGoal) { return this.CMS_BLOCKS_SLUG.finished }
			return this.isOptedIn ? this.CMS_BLOCKS_SLUG.optedIn : this.CMS_BLOCKS_SLUG.noOptedIn
		},
		doShowCalendar () {
			return (this.isOptedIn || this.isFinished) &&
				this.cmsPageLoaded &&
				this.cmsPageLoaded !== this.CMS_BLOCKS_SLUG.noOptedIn
		},
		gratitudesAnswersKey () {
			if (!this.fmlFormData) { return }
			return this.fmlFormData.FormQuestionSets[0].FormQuestions
				.find(({ FormQuestionTitle }) => FormQuestionTitle === 'Gratitudes')?.ID
		},
		analogGratitudesAnswersKey () {
			if (!this.fmlFormData) { return }
			return this.fmlFormData.FormQuestionSets[0].FormQuestions
				.find(({ FormQuestionTitle }) => FormQuestionTitle === 'Analog Gratitudes')?.ID
		},
		events () {
			if (!this.fmlFormResponses.length) return []
			const events = []
			this.fmlFormResponses.forEach(({ SubmittedDate, FormResponseAnswers }) => {
				if (Array.isArray(FormResponseAnswers[this.gratitudesAnswersKey].AnswerContent)) {
					FormResponseAnswers[this.gratitudesAnswersKey].AnswerContent
						.forEach(({ field_gratitude }) => {
							events.push({
								name: field_gratitude.AnswerContent,
								start: SubmittedDate.substring(0,10),
								end: SubmittedDate.substring(0,10),
								timed: false,
							})
						})
				}
				if (FormResponseAnswers[this.analogGratitudesAnswersKey].AnswerContent) {
					const event = {
						name: 'Analog Gratitudes',
						start: SubmittedDate.substring(0,10),
						end: SubmittedDate.substring(0,10),
						timed: false,
					}
					events.push(event, event, event)
				}
			})
			return events
		},
		selectedDateString () {
			if (!this.selectedDate) { return }
			return moment(this.selectedDate, 'YYYY-MM-DD').format('MMMM D, YYYY')
		},
		selectedDateData () {
			if (!this.fmlFormResponses.length) { return undefined } 
			return this.fmlFormResponses.find(({ SubmittedDate }) => SubmittedDate.substring(0,10) === this.selectedDate)
		},
		selectedDateGratitudesInitial () {
			return  {
				gratitudes: this.selectedDateData?.FormResponseAnswers[this.gratitudesAnswersKey].AnswerContent || [],
				hasAnalogGratitudes: this.selectedDateData?.FormResponseAnswers[this.analogGratitudesAnswersKey].AnswerContent || false
			}
		},
		hasNewGratitude () {
			return this.newGratitudes.some(({ gratitude }) => !!gratitude.trim())
		},
		hasAnalogGratitudesEdited () {
			return this.selectedDateGratitudesInitial.hasAnalogGratitudes !== this.selectedDateGratitudes.hasAnalogGratitudes
		},
		submitEnabled () {
			return this.hasNewGratitude || this.hasAnalogGratitudesEdited || this.isSelectedDateGratitudesEdited
		},
		streakCounter () {
			if (!this.fmlFormResponses.length) return 0
			const today = moment().format('YYYY-MM-DD')
			const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD')
			const datesWithGoal = this.fmlFormResponses
				.filter(this.checkDateGoal)
				.map(({ SubmittedDate }) => SubmittedDate.substring(0,10))
				.sort((a, b) => moment(a).isAfter(b, 'day') ? -1 : 1)
			if (datesWithGoal[0] !== today && datesWithGoal[0] !== yesterday) { return 0 }
			let index = 0
			let streakDates = [datesWithGoal[0]]
			while (
				index < datesWithGoal.length - 1 &&
				this.checkDateSequence(datesWithGoal[index], datesWithGoal[index + 1])
			) {
				streakDates.push(datesWithGoal[index + 1])
				index += 1
			}
			return streakDates.length
		},
		badgeCounter () {
			const datesWithGoal = this.fmlFormResponses
				.filter(this.checkDateGoal)
				.map(({ SubmittedDate }) => SubmittedDate.substring(0,10))
				.filter(date => moment(date).isBetween(this.gratitudeChallengeConfig.startDate, this.gratitudeChallengeConfig.endDate, undefined, '[]'))
			return datesWithGoal.length
		},
    },
	watch: {
		calendarValue (val) {
			this.getCurrentMonth(val)
		},
		selectedDateGratitudesInitial () {
			this.setSelectedDateGratitudes()
		},
		'selectedDateGratitudes.gratitudes': {
			handler () {
				if (this.currentDialogStep === 2) {
					this.checkIfSelectedDateGratitudesChanged()
				}
			},
			deep: true,
		},
	},
    methods: {
		async loadFormData () {
			this.loading = true
			const formLoaded = await this.loadForm()
			if (formLoaded?.error) {
				this.showError(formLoaded.msg, formLoaded.cause)
				this.hasError = true
				this.loading = false
				return
			}
			const formResponsesLoaded = await this.loadFormResponses()
			if (formResponsesLoaded?.error) {
				this.showError(formResponsesLoaded.msg, formResponsesLoaded.cause)
				this.hasError = true
				this.loading = false
				return
			}
			this.hasError = false
			this.loading = false
		},
		async loadForm () {
			const res = await QuilityAPI.getFormWithSlug(this.FML_FORM_SLUG)
			if (res.error) {
				return {
					error: true,
					msg: "Whoops! Can't find form!",
					cause: res.text,
				}
			}
			this.fmlFormData = res
		},
		async loadFormResponses () {
			const res = await QuilityAPI.getFormDataWithSlug(
				this.FML_FORM_SLUG,
				{ filter_level: 'email', ResponseDate: '-All-', itemsPerPage: -1 },
				{},
				this.user.AgentCode,
			)
			if (res.error) {
				return {
					error: true,
					msg: "Whoops! Can't find form responses!",
					cause: res.text,
				}
			}
			this.fmlFormResponses = this.filterFormResponses(res)
		},
		async loadFormResponseTemplate () {
			if (this.formResponseTemplate) {
				return Promise.resolve(cloneDeep(this.formResponseTemplate))
			}
			const res = await QuilityAPI.getFormResponseTemplate(this.fmlFormData.ID)
			if (res.error) {
				return {
					error: true,
					msg: "Whoops! Can't find form response template!",
					cause: res.text,
				}
			}
			this.formResponseTemplate = res
			return cloneDeep(this.formResponseTemplate)
		},
		async save () {
			if (this.saving) { return }
			if (!this.validateForm()) { return }
			this.saving = true
			let response
			if (this.selectedDateData) {
				response = cloneDeep(this.selectedDateData)
			} else {
				response = await this.loadFormResponseTemplate()
				if (response.error) {
					this.showError(response.msg, response.cause)
					this.saving = false
					return
				}
				response.SubmittedDate = this.selectedDate
			}
			response.FormResponseAnswers[this.gratitudesAnswersKey].AnswerContent = [...this.selectedDateGratitudes.gratitudes]
			response.FormResponseAnswers[this.analogGratitudesAnswersKey].AnswerContent = this.selectedDateGratitudes.hasAnalogGratitudes
			if (this.hasNewGratitude) {
				response.FormResponseAnswers[this.gratitudesAnswersKey].AnswerContent.push(
					...this.newGratitudes.map((newGratitude) => makeNewGratitudeFormAnswer(
						{ ...newGratitude, submittedDate: moment().format('YYYY-MM-DD') }
					))
				)
			}
			const res = await QuilityAPI.saveFormResponse(response.FormID, response)
			if (res.error) {
				this.showError('Oops! There was a problem saving your changes.', res.text)
				this.saving = false
				return
			}
			this.saving = false
			this.checkCelebrations(res)
			this.updateFmlFormResponses(res)
			this.cancel()
		},
		joinChallenge () {
			if (this.saving) { return }
			this.saving = true
			const configValue = cloneDeep(this.user.config?.GratitudeChallenge?.ConfigValue || [])
			configValue.push({
				title: GRATITUDES_CHALLENGE_TITLE,
				startDate: moment().format('YYYY-MM-DD'),
				endDate: moment().add(this.challengeDuration - 1, 'days').format('YYYY-MM-DD'),
				hasBadge: false,
			})
			QuilityAPI.updateConfig('GratitudeChallenge', configValue, 'json')
				.then((res) => {
					if (res.error || !res.success) {
						this.saving = false
						this.showError('Oops! There was a problem saving user config.', res.cause)
						return
					}
					this.saving = false
					this.loadFormData()
					EventBus.$emit('gratitudes-challenge-joined')
				})
		},
		addBadgeToConfig () {
			const configValue = cloneDeep(this.user.config.GratitudeChallenge.ConfigValue)
			const index = configValue.findIndex(({ title }) => title === GRATITUDES_CHALLENGE_TITLE)
			const updatedConfig = [
				...configValue.slice(0, index),
				{ ...configValue[index], hasBadge: true },
				...configValue.slice(index + 1),
			]
			QuilityAPI.updateConfig('GratitudeChallenge', updatedConfig, 'json')
				.then((res) => {
					if (res.error || !res.success) {
						this.showError('Oops! There was a problem saving user config.', res.cause)
						return
					}
					EventBus.$emit('gratitudes-challenge-got-badge')
				})
		},
		filterFormResponses (res) {
			const filtered = res.data.filter(item => item.SubmittedDate)
			filtered.forEach(item => item.SubmittedDate = item.SubmittedDate.substring(0,10))
			const grouped = groupBy(filtered, 'SubmittedDate')
			return Object.values(grouped).map(response => {
				// only one response by SubmittedDate
				if (response.length === 1) { return response[0] }
				// if there is more than one, then filter by the existing AnswerContent and check if there is only one left
				const filtered = response.filter((res) => (
					res.FormResponseAnswers[this.gratitudesAnswersKey].AnswerContent !== null ||
					res.FormResponseAnswers[this.analogGratitudesAnswersKey].AnswerContent !== null
				))
				if (filtered.length === 1) { return filtered[0] }
				// else choose the one with more gratitudes
				return response.reduce((acc, res) => {
					const accGratitudesLength = acc.FormResponseAnswers[this.gratitudesAnswersKey].AnswerContent?.length
					const resGratitudesLength = res.FormResponseAnswers[this.gratitudesAnswersKey].AnswerContent?.length
					if (accGratitudesLength) {
						return (resGratitudesLength > accGratitudesLength) ? res : acc
					} else {						
						return resGratitudesLength ? res : acc
					}
				})
			})
		},
		prevCalendar () {
			this.$refs.calendar.prev()
		},
		nextCalendar () {
			this.$refs.calendar.next()
		},
		getCurrentMonth (dateString) { // YYYY-MM-DD
			this.currentMonth = moment(dateString, 'YYYY-MM-DD').format('MMMM YYYY')
		},
		setSelectedDateGratitudes () {
			this.selectedDateGratitudes = {
				gratitudes: cloneDeep(this.selectedDateGratitudesInitial.gratitudes),
				hasAnalogGratitudes: this.selectedDateGratitudesInitial.hasAnalogGratitudes,
			}
		},
		checkIfSelectedDateGratitudesChanged () {
			this.isSelectedDateGratitudesEdited = this.selectedDateGratitudesInitial.gratitudes
				.some(({ field_title, field_gratitude, field_isShareable }, idx) => {
					const entry = this.selectedDateGratitudes.gratitudes[idx]
					return entry.field_title.AnswerContent !== field_title.AnswerContent ||
						entry.field_gratitude.AnswerContent !== field_gratitude.AnswerContent ||
						entry.field_isShareable.AnswerContent !== field_isShareable.AnswerContent
				})
		},
		updateFmlFormResponses (response) {
			const index = this.fmlFormResponses.findIndex(({ SubmittedDate }) => SubmittedDate.substring(0,10) === this.selectedDate)
			if (index === -1) {
				this.fmlFormResponses = [...this.fmlFormResponses, response]
			} else {
				this.fmlFormResponses = [
					...this.fmlFormResponses.slice(0, index),
					response,
					...this.fmlFormResponses.slice(index + 1),
				]
			}
		},
		checkCelebrations (res) {
			const count = res.FormResponseAnswers[this.gratitudesAnswersKey].AnswerContent?.length
			const hasAnalogGratitudes =	res.FormResponseAnswers[this.analogGratitudesAnswersKey].AnswerContent
			if (this.hasAnalogGratitudesEdited && hasAnalogGratitudes) {
				this.showCelebrations()
				return
			} else if (this.hasNewGratitude) {
				const gratitudes = hasAnalogGratitudes ? count + 3 : count
				if (gratitudes >= this.minGoal) {
					this.showCelebrations()
				}
			}
		},
		checkLastDayGoal () {
			const lastDayData = this.isLastDay
				? this.fmlFormResponses.find(({ SubmittedDate }) => SubmittedDate.substring(0,10) === this.gratitudeChallengeConfig.endDate)
				: undefined
			if (!lastDayData) {
				this.hasLastDayGoal = false
			} else {
				this.hasLastDayGoal = this.checkDateGoal(lastDayData)
			}
		},
		checkDateGoal (res) {
			const hasMinGoal = res.FormResponseAnswers[this.gratitudesAnswersKey].AnswerContent?.filter(
				({ field_submittedDate }) => field_submittedDate.AnswerContent === res.SubmittedDate.substring(0,10)
			).length >= this.minGoal
			const hasAnalogGratitudes = res.FormResponseAnswers[this.analogGratitudesAnswersKey].AnswerContent
			return hasMinGoal || hasAnalogGratitudes
		},
		checkDateSequence (after, before) { // YYYY-MM-DD
			return moment(after).subtract(1, 'days').isSame(before, 'day')
		},
		showCelebrations () {
			this.doShowCelebrations = true
			this.celebrationsTimer = setTimeout(() => {
				this.doShowCelebrations = false
				if (this.isLastDay) {
					this.processLastDay()
				}
			}, 2500)
		},
		processLastDay () {
			this.checkLastDayGoal()
			if (this.hasLastDayGoal) {
				this.close()
				this.checkBadge()
			}
		},
		checkBadge() {
			if (this.hasBadge) { return }
			if (this.badgeCounter >= this.challengeDuration) {
				this.addBadgeToConfig()
			} else if (this.hasLastDayGoal) {
				EventBus.$emit('gratitudes-challenge-completed')
			}
		},
		hideCelebrations () {
			this.doShowCelebrations = false
			clearTimeout(this.celebrationsTimer)
		},
		showDayEvents (evt) {
			this.selectedDate = evt.date
			this.currentDialogStep = 1
			this.showForm = true
		},
		editDateGratitudes () {
			this.currentDialogStep = 2
			this.hideCelebrations()
		},
		addNewGratitude () {
			this.newGratitudes = [...this.newGratitudes, getNewGratitudeTemplate()]
		},
		removeGratitude (index) {
			this.newGratitudes = [
				...this.newGratitudes.slice(0, index),
				...this.newGratitudes.slice(index + 1),
			]
		},
		validateForm () {
			if (!this.$refs['form'].validate()) {
                this.goToTop()
                this.warning = 'Please correct invalid fields.'
                setTimeout(() => { this.warning = '' }, 6000)
                return false
            }
			const hasEmptyGratitudes = this.newGratitudes
				.some(({ title, gratitude, isShareable }) => (title.trim() || isShareable) && !gratitude.trim())
			if (hasEmptyGratitudes) {
				this.warning = 'In order to save the entry, you must complete the gratitude field. If you filled in only the title or selected the checkbox, then either fill in the gratitude field as well, or delete this entry (if it is not needed).'
                setTimeout(() => { this.warning = '' }, 6000)
				return false
			}
			return true
		},
		cancel () {
			this.currentDialogStep = 1
			this.newGratitudes = [getNewGratitudeTemplate()]
			this.setSelectedDateGratitudes()
			if (this.isLastDay) {
				this.checkLastDayGoal()
			}
		},
		close () {
			this.showForm = false
			this.clearDialogData()
		},
        goToTop () {
            this.$vuetify.goTo('#stepper', {
                duration: 500,
                easing: 'easeOutCubic',
                offset: 300
            })
        },
		clearDialogData () {
			this.selectedDate = ''
			this.selectedDateGratitudes = { gratitudes: [], hasAnalogGratitudes: false }
			this.newGratitudes = [getNewGratitudeTemplate()]
			this.isSelectedDateGratitudesEdited = false
			this.currentDialogStep = 1
			this.hideCelebrations()
		},
		setCalendarValueTimer () {
			const now = moment().toDate()
			const tomorrow = moment().add(1, 'days').startOf('day').toDate()
			const delay = tomorrow.getTime() - now.getTime() + 1000
			this.calendarValueTimer = setTimeout(() => {
				this.calendarValue = moment().format('YYYY-MM-DD')
				this.$refs.calendar.updateTimes()
				this.setCalendarValueTimer()
			}, delay)
		},
    },
}
</script>

<style lang="scss" scoped>
$block: calendar;

$color-primary: #2C1C5D;
$color-secondary: #8B8B8B;
$color-accent: #FFA580;

$color: $color-primary;
$header-bg: $color-accent;
$weekday-bg: #EEE6DD;
$day-bg: white;
$day-bg--disallowed: #f8f8f8;
$present-day-bg: #FF7D46;
$event-color: $color-accent;

.#{$block} {
	--font-title: 2rem;
	--font-weekday: 1rem;
	--font-day: 1.25rem;
	--padding-header: 1rem 0;
	--padding-day-label: 0.5rem;
	--event-size: 1rem;
	--event-margin: 0.25rem;
	--weekly-day-height: 6rem;

	&--mobile {
		--font-title: 1.25rem;
		--font-weekday: 0.75rem;
		--font-day: 1rem;
		--padding-day-label: 0.25rem;
		--event-size: 0.5rem;
		--event-margin: 0.2rem 0.1rem;
		--weekly-day-height: 4.5rem;

		.#{$block}__header {
			justify-content: space-between;
		}
	}

	&__loader-container {
		height: 4px;
	}

	&__header {
		width: 100%;
		padding: var(--padding-header);
		display: flex;
		justify-content: center;
		align-items: center;
		color: $color;
		font-size: var(--font-title);
		font-weight: bold;
		background-color: $header-bg;
	}

	&__header-month {
		display: flex;
		justify-content: center;
		text-align: center;
		line-height: 1.2;
	}

}

.#{$block}-dialog {
	display: flex;
	flex-direction: column;
	height: 100%;
	min-height: 20rem;
	color: $color-primary;

	&__card-title {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__title {
		color: $color-accent;
		font-weight: bold;
	}

	&__date {
		font-size: 1rem;
		font-weight: bold;
	}

	&__note {
		color: $color-secondary;
	}

	.v-stepper {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		box-shadow: none;
		overflow-y: unset;

		.v-stepper__content,
		&::v-deep .v-stepper__wrapper {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
		}
	}

	&__btn-wrapper {
		max-width: 1200px;
		margin: 0 auto;
		text-align: right;
	}

	&__btn {
		border-radius: 0.2rem;
		font-weight: 600;

		&:not(.v-btn--text, .v-btn--icon) {
			background-color: $color-accent !important;
			color: white;
		}
	}

	&__btn-icon {
		margin-left: -0.25rem;
		font-size: 2rem;
		line-height: 1rem;
	}
}

.#{$block}-form {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-grow: 1;

	.v-text-field--outlined {
		border-radius: 2px;
	}

	::v-deep .#{$block}-form__text-field {
		fieldset {
			color: $color-accent !important;
			border-color: currentColor;
		}
	}
}

.#{$block}-gratitude {
	&__title {
		font-weight: bold;
	}

	&__text {
		margin-bottom: 0;
		text-align: justify;
	}

	&__divider {
		margin: 2.5rem 0;
		border-top-width: 1px;
		border-color: $color-accent;
	}
}

::v-deep .v-dialog {
	position: relative;
}

.theme--light.v-calendar {
	border: 1px solid $weekday-bg;

	&.calendar--disabled {
		pointer-events: none;
		opacity: 0.5;
	}

	&::v-deep .v-calendar-weekly__head-weekday {
		padding: 0.5rem 0.25rem;
		font-size: var(--font-weekday);
		font-weight: 600;
		color: $color;
		background-color: $weekday-bg;
		border: 1px solid white;

		&.v-past {
			color: $color;
		}
	}

	&::v-deep .v-calendar-weekly__day {
		display: flex;
		justify-content: flex-start;
		align-items: flex-end;
		flex-wrap: wrap;
		border: 1px solid $weekday-bg;
		height: var(--weekly-day-height);
		background-color: $day-bg;
		font-size: var(--font-day);
		font-weight: 600;
		overflow: hidden;
		cursor: pointer;

		&.v-present {
			background-color: $present-day-bg;
			color: white;
		}

		&.v-outside:is(.v-past, .v-future) {
			background-color: $day-bg--disallowed;
			pointer-events: none;

			.v-calendar-weekly__day-label,
			.v-event {
				display: none;
			}
		}

		&.v-future {
			background-color: $day-bg--disallowed;
			color: $color-secondary;
			pointer-events: none;
		}

		.v-calendar-weekly__day-label {
			align-self: flex-start;
			width: 100%;
			margin: 0;
			text-align: left;

			.day-label {
				display: inline-block;
				padding: var(--padding-day-label);

				&--reached {
					&::before {
						content: '+';
					}
				}

				&--reached-analog {
					&::after {
						content: '+';
						color: $color-accent;
					}
				}
			}
		}

		.v-event {
			flex-shrink: 0;
			display: inline-flex;
			margin: var(--event-margin) !important;
			height: unset !important;
			width: unset !important;
			background-color: transparent !important;
			border-color: transparent !important;
			border-radius: 50%;
			font-size: var(--event-size);
			line-height: 1;
			pointer-events: none;
		}
	}
}

.event-circle {
	display: inline-block;
	width: var(--event-size);
	height: var(--event-size);
	border-radius: 50%;
	background-color: $event-color;
}
</style>
