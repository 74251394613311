<template>
  <div class="q-summit-step-background-bottom" :class="{ 'q-summit-step-background-bottom--mobile': $vuetify.breakpoint.smAndDown }">
    <slot></slot>
  </div>

</template>

<script>
export default {}
</script>

<style lang="scss">
.q-summit-step-background-bottom {
    height: 100%;
    width: 100%;

    &__left, &__right, &__center {
        position: absolute;
        bottom: 0;
        z-index: 1;
    }

    &__left {
        left: 0;
    }

    &__center {
        left: 50%;
        transform: translateX(-50%);
        z-index: 0;
    }

    &__right {
        right: 0;
    }

    &--mobile {
        .q-summit-step-background-bottom {
            &__left, &__right {
                height: 200px;
            }
        }
    }
}
</style>